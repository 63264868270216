import React, { useMemo } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Formik, Field } from 'formik'
import { setNullOnEmptyValues, transformErrorForForm } from './Fields/utils'
import { MultiSelectField } from './Fields'

const ModalPolicies = ({ isOpen, toggle, save, policies, currentPolicies }) => {
  const initialValues = useMemo(() => {
    if (currentPolicies && currentPolicies.policies.length > 0) {
      return {
        policies: currentPolicies.policies,
      }
    }
    return {
      policies: [],
    }
  }, [currentPolicies])

  const policiesToUse = useMemo(() => {
    if (!policies || !currentPolicies) {
      return []
    }
    return policies.filter(
      (policy) =>
        !currentPolicies.inherited_policies.filter(
          (policyInherited) => policyInherited.id === policy.id
        ).length
    )
  }, [currentPolicies, policies])

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md" centered data-test="modal-policies">
      <ModalHeader toggle={toggle}>Policy</ModalHeader>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          return save(setNullOnEmptyValues(values)).then(
            () => {
              actions.setSubmitting(false)
            },
            (error) => {
              actions.setSubmitting(false)
              actions.setErrors(transformErrorForForm(error))
            }
          )
        }}
        render={({ handleSubmit, values, isSubmitting, errors }) => (
          <>
            <ModalBody>
              <form onSubmit={handleSubmit}>
                {currentPolicies &&
                  currentPolicies.inherited_policies.length > 0 && (
                    <div className="d-flex mb-2">
                      <label>Policies ereditate:</label>
                      {currentPolicies.inherited_policies.map((u) => (
                        <div
                          className="btn btn-sm btn-outline-primary btn-disabled mx-2 mt-1"
                          key={u.id}
                        >
                          {u.name}
                        </div>
                      ))}
                    </div>
                  )}
                <Field
                  label="Policy"
                  isLoading={policies === null}
                  getOptionLabel={(p) => p.name}
                  options={policiesToUse ?? []}
                  noOptionsMessage={() => 'Non ci sono policies selezionabili.'}
                  component={MultiSelectField}
                  placeholder={'Seleziona una o più policies'}
                  name="policies"
                />
              </form>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
              <Button
                disabled={isSubmitting}
                color="igp-blue"
                outline
                onClick={toggle}
              >
                Chiudi
              </Button>
              <Button
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
                color="igp-blue"
              >
                Conferma
              </Button>
            </ModalFooter>
          </>
        )}
      />
    </Modal>
  )
}

export default ModalPolicies
