import React, { useMemo } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import {
  fixInitialValuesNull,
  setNullOnEmptyValues,
  transformErrorForForm,
} from './Fields/utils'
import { InputField } from './Fields'

const FolderSchema = Yup.object().shape({
  name: Yup.string().required().label('Nome'),
})

const ModalFolder = ({ isOpen, toggle, save, folder }) => {
  const initialValues = useMemo(() => {
    if (folder) {
      return fixInitialValuesNull(folder)
    }
    return {
      name: '',
      description: '',
    }
  }, [folder])

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md" centered data-test="modal-folder">
      <ModalHeader toggle={toggle}>
        {folder ? 'Modifica' : 'Crea'} Cartella
      </ModalHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={FolderSchema}
        onSubmit={(values, actions) => {
          return save(setNullOnEmptyValues(values)).then(
            () => {
              actions.setSubmitting(false)
            },
            (error) => {
              actions.setSubmitting(false)
              actions.setErrors(transformErrorForForm(error))
            }
          )
        }}
        render={({ handleSubmit, values, isSubmitting, errors }) => (
          <>
            <ModalBody>
              <form onSubmit={handleSubmit}>
                <Field
                  rowLayout={false}
                  label="Nome *"
                  type="text"
                  name="name"
                  placeholder="Inserisci il nome della cartella"
                  className="mb-2"
                  component={InputField}
                />
                <Field
                  rowLayout={false}
                  label="Descrizione"
                  type="textarea"
                  rows={5}
                  placeholder="Inserisci la descrizione della cartella"
                  name="description"
                  className="mb-2"
                  component={InputField}
                />
              </form>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
              <Button
                disabled={isSubmitting}
                color="igp-blue"
                outline
                onClick={toggle}
              >
                Chiudi
              </Button>
              <Button
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
                color="igp-blue"
              >
                Conferma
              </Button>
            </ModalFooter>
          </>
        )}
      />
    </Modal>
  )
}

export default ModalFolder
