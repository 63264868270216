import { Field, Form, Formik } from 'formik'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import * as Yup from 'yup'
import { useParams } from 'react-router'
import { useCheckResetToken, useResetPassword } from '../../hooks/reset'
import { transformErrorForForm } from '../../components/Fields/utils'
import { InputField } from '../../components/Fields'
import { Button, FormGroup } from 'reactstrap'

const ResetSchema = Yup.object().shape({
  password: Yup.string().required().label('Password'),
  repeatPassword: Yup.string()
    .required()
    .label('Ripeti password')
    .oneOf([Yup.ref('password'), null], 'Le password non corrispondono.'),
})

export default function Reset() {
  const { token } = useParams()
  const [{ data: isTokenOk, error }] = useCheckResetToken(token)
  const { run: resetPassword } = useResetPassword()[1]
  const [activated, setActivated] = useState(false)

  if (error) {
    return <div>Token scaduto o invalido.</div>
  }

  if (!isTokenOk) {
    return null
  }

  return (
    <div>
      {activated && (
        <div className="alert alert-success alert-login">
          Password cambiata. Vai al <Link to="/">Login</Link>.
        </div>
      )}
      <div className="form-recover">
        <h3 className='login'>Reset Password</h3>
        <div>Inserisci la nuova password da utilizzare per il tuo account.</div>
        <Formik
          validationSchema={ResetSchema}
          initialValues={{
            password: '',
            repeatPassword: '',
          }}
          onSubmit={(values, actions) =>
            resetPassword
              .onSuccess(() => {
                setActivated(true)
              })
              .asPromise(token, {
                password: values.password,
              })
              .catch((err) => {
                actions.setErrors(transformErrorForForm(err))
              })
          }
        >
          {({ handleSubmit, isSubmitting, resetForm, status }) => (
            <Form autoComplete='off' onSubmit={handleSubmit} isHorizontal>
              <div className="mt-3">
                <Field
                  isRequired
                  type="password"
                  label="Password"
                  name="password"
                  autoComplete="new-password"
                  component={InputField}
                />
                <Field
                  isRequired
                  type="password"
                  label="Ripeti password"
                  name="repeatPassword"
                  component={InputField}
                />
                <FormGroup>
                  <Button
                    className="w-100"
                    color="primary"
                    disabled={isSubmitting || activated}
                    type="submit"
                  >
                    Resetta Password
                  </Button>
                </FormGroup>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
