import sortBy from 'lodash/sortBy'
import { deps, rj, useRj, useRunRj } from 'react-rocketjump'
import api from '../api'

const FoldersListState = rj({
  name: 'foldersList',
  effectCaller: 'configured',
  effect:
    (t) =>
    (params = {}) => {
      return api.auth(t).get('/api/folders/ls/', params)
    },
  mutations: {
    addFile: rj.mutation.single({
      effect: (token) => (data) => {
        const formData = new FormData()
        formData.append('name', data.name)
        formData.append('description', data.description)
        formData.append('write_file', data.file)
        formData.append('folder', data.folder)
        return api.auth(token).post(`/api/shared-files`, formData)
      },
      updater: (state, result) => ({
        ...state,
        data: {
          ...state.data,
          content_files: sortBy(
            state.data.content_files.concat(result),
            'name'
          ),
        },
      }),
    }),
    addFolder: rj.mutation.single({
      effect: (token) => (data) => {
        return api.auth(token).post(`/api/folders`, data)
      },
      updater: (state, result) => ({
        ...state,
        data: {
          ...state.data,
          content_folders: sortBy(
            state.data.content_folders.concat(result),
            'name'
          ),
        },
      }),
    }),
    patchFolder: rj.mutation.single({
      effect: (token) => (id, data) => {
        return api.auth(token).patch(`/api/folders/${id}`, data)
      },
      updater: (state, result) => ({
        ...state,
        data: {
          ...state.data,
          content_folders: state.data.content_folders.map((folder) =>
            folder.id === result.id ? result : folder
          ),
        },
      }),
    }),
    patchSharedFile: rj.mutation.single({
      effect: (token) => (id, data) => {
        const formData = new FormData()
        if (data.name) {
          formData.append('name', data.name)
        }
        if (data.description) {
          formData.append('description', data.description)
        }
        if (data.file && data.file instanceof File) {
          formData.append('write_file', data.file)
        }
        if (data.folder) {
          formData.append('folder', data.folder)
        }
        return api.auth(token).patch(`/api/shared-files/${id}`, formData)
      },
      updater: (state, result) => ({
        ...state,
        data: {
          ...state.data,
          content_files: state.data.content_files.map((file) =>
            file.id === result.id ? result : file
          ),
        },
      }),
    }),
    deleteSharedFile: rj.mutation.single({
      effect: (token) => (id) => {
        return api
          .auth(token)
          .mapResponse(() => ({ id }))
          .delete(`/api/shared-files/${id}`)
      },
      updater: (state, result) => ({
        ...state,
        data: {
          ...state.data,
          content_files: state.data.content_files.filter(
            (file) => file.id !== result.id
          ),
        },
      }),
    }),
    deleteFolder: rj.mutation.single({
      effect: (token) => (id) => {
        return api
          .auth(token)
          .mapResponse(() => ({ id }))
          .delete(`/api/folders/${id}`)
      },
      updater: (state, result) => ({
        ...state,
        data: {
          ...state.data,
          content_folders: state.data.content_folders.filter(
            (folder) => folder.id !== result.id
          ),
        },
      }),
    }),
  },
})

const NO_PARAMS = {}
export function useFoldersList(params = NO_PARAMS) {
  return useRunRj(FoldersListState, [deps.maybe(params)], false)
}

const downloadReportRj = rj({
  effectCaller: 'configured',
  effect: (token) => () => {
    return api
      .auth(token)
      .request({
        responseType: 'arraybuffer',
      })
      .get(`/api/folders/export_report/`)
  },
})

export function useDownloadReport() {
  return useRj(downloadReportRj)
}

const downloadStatsRj = rj({
  effectCaller: 'configured',
  effect: (token) => () => {
    return api
      .auth(token)
      .request({
        responseType: 'arraybuffer',
      })
      .get(`/api/folders/export_stats/`)
  },
})

export function useDownloadStats() {
  return useRj(downloadStatsRj)
}
