import React, { useMemo } from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from 'reactstrap'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { fixInitialValuesNull, transformErrorForForm } from './Fields/utils'
import { FileField, InputField } from './Fields'

const FileSchema = Yup.object().shape({
  name: Yup.string().required().label('Nome'),
  file: Yup.mixed().required().label('File'),
})

const ModalFile = ({ isOpen, toggle, save, file }) => {
  const initialValues = useMemo(() => {
    if (file) {
      return fixInitialValuesNull(file)
    }
    return {
      name: '',
      description: '',
      file: '',
    }
  }, [file])

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md" centered data-test="modal-file">
      <ModalHeader toggle={toggle}>
        {file ? 'Modifica' : 'Crea'} File
      </ModalHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={FileSchema}
        onSubmit={(values, actions) => {
          return save(values).then(
            () => {
              actions.setSubmitting(false)
            },
            (error) => {
              actions.setSubmitting(false)
              actions.setErrors(transformErrorForForm(error))
            }
          )
        }}
      >
        {({
          handleSubmit,
          values,
          isSubmitting,
          errors,
          setValues,
          validateForm,
        }) => (
          <>
            <ModalBody>
              {console.log(values, errors)}
              <form onSubmit={handleSubmit}>
                <Field
                  rowLayout={false}
                  label="Nome *"
                  type="text"
                  placeholder="Inserisci il nome del file"
                  name="name"
                  className="mb-2"
                  component={InputField}
                />
                <Field
                  rowLayout={false}
                  label="Descrizione"
                  type="textarea"
                  rows={5}
                  placeholder="Inserisci la descrizione del file"
                  name="description"
                  className="mb-2"
                  component={InputField}
                />
                <Field
                  rowLayout={false}
                  type="file"
                  name="file"
                  onChange={(event) => {
                    const file = event.currentTarget.files[0]
                    const nameParts = file.name.split('.')
                    const nextValues = {
                      ...values,
                      file: file,
                      name: nameParts.slice(0, nameParts.length - 1).join('.'),
                    }
                    setValues(nextValues)
                    validateForm(nextValues)
                  }}
                  className="mb-2"
                  component={FileField}
                />
              </form>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
              <Button
                disabled={isSubmitting}
                color="igp-blue"
                outline
                onClick={toggle}
              >
                Chiudi
              </Button>
              <Button
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
                className='d-flex justify-content-between align-items-center'
                color="igp-blue"
              >
                {isSubmitting ? (
                  <>
                    <Spinner className='mx-1' size={'sm'} />
                    Conferma
                  </>
                ) : (
                  'Conferma'
                )}
              </Button>
            </ModalFooter>
          </>
        )}
      </Formik>
    </Modal>
  )
}

export default ModalFile
