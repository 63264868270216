import { Route, Routes } from 'react-router'
import PageNotFound from '../../components/PageNotFound'
import PoliciesList from './PoliciesList'
import PolicyDetail from './PolicyDetail'

export default function Policies() {
  return (
    <Routes>
      <Route index element={<PoliciesList />} />
      <Route path=":id" element={<PolicyDetail />} />
      <Route path="new" element={<PolicyDetail />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}
