import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom'
import { useAuthUser } from 'use-eazy-auth'
import Auth from './Auth'
import PageNotFound from './components/PageNotFound'
import { RequireAuth, RequireGuest } from './lib'
import Login from './pages/Login'
import Recover from './pages/Recover'
import Reset from './pages/Reset'
import Layout from './components/Layout'
import LayoutSidebar from './components/LayoutSidebar'
import Profile from './pages/Profile'
import Groups from './pages/Groups'
import Users from './pages/Users'
import Policies from './pages/Policies'
import Folders from './pages/Folders'
import FileBrowserUser from './pages/FileBrowserUser'
import ActivationUser from './pages/ActivationUser'

function adminAccess(user) {
  if (!user.administrator) {
    return '/'
  }
}

function userAccess(user) {
  if (!user.external_user) {
    return '/'
  }
}

function HomeRedirect() {
  const { user } = useAuthUser()

  if (user.administrator) {
    return <Navigate to="/folders" />
  }
  if (user.external_user) {
    return <Navigate to="/file-browser" />
  }

  return <Navigate to="/profile" />
}

function LayoutUnified({ children }) {
  const { user } = useAuthUser()

  return user?.administrator ? (
    <LayoutSidebar>{children}</LayoutSidebar>
  ) : (
    <Layout>{children}</Layout>
  )
}

function App() {
  return (
    <Auth>
      <Router>
        <Routes>
          {/* GUEST */}
          <Route
            path="/"
            element={
              <RequireGuest>
                <LayoutUnified />
              </RequireGuest>
            }
          >
            <Route path="login" element={<Login />} />
            <Route path="activate/:token" element={<ActivationUser />} />
            <Route path="recover" element={<Recover />} />
            <Route path="reset/:token" element={<Reset />} />
          </Route>

          {/* AUTH COMMON */}
          <Route
            path="/"
            element={
              <RequireAuth>
                <LayoutUnified />
              </RequireAuth>
            }
          >
            <Route path="profile" element={<Profile />} />
            <Route index element={<HomeRedirect />} />
          </Route>

          {/* admins */}
          <Route
            path="/"
            element={
              <RequireAuth redirectTest={adminAccess}>
                <LayoutSidebar />
              </RequireAuth>
            }
          >
            <Route path="folders/*" element={<Folders />} />
            <Route path="groups/*" element={<Groups />} />
            <Route path="policies/*" element={<Policies />} />
            <Route path="users/*" element={<Users />} />

            {/* <Route path="profile" element={<Profile />} /> */}
          </Route>
          {/* users */}
          <Route
            path="/"
            element={
              <RequireAuth redirectTest={userAccess}>
                <Layout />
              </RequireAuth>
            }
          >
            <Route path="file-browser/*" element={<FileBrowserUser />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </Auth>
  )
}

export default App
