import { Route, Routes } from 'react-router'
import PageNotFound from '../../components/PageNotFound'
import FolderContent from './FolderContent'
import FoldersList from './FoldersList'

export default function Folders() {
  return (
    <Routes>
      <Route index element={<FoldersList />} />
      <Route path=":id" element={<FolderContent />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}
