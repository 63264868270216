import React, { useMemo } from 'react'
import keyBy from 'lodash/keyBy'
import { getIn, useField } from 'formik'
import classNames from 'classnames'
import omit from 'lodash/omit'
import Switch from 'react-switch'
import {
  Input,
  FormGroup,
  FormFeedback,
  Label,
  Col,
  InputGroup,
  InputGroupText,
} from 'reactstrap'
import './locale'
import './fields.css'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'

export const FieldRadio = ({ field, form, onClick, ...props }) => {
  const { value: checkboxValue, ...passProps } = props
  return (
    <input
      type={'radio'}
      onClick={onClick}
      {...field}
      {...passProps}
      value={checkboxValue}
      checked={checkboxValue === field.value}
    />
  )
}

export const InputField = ({
  field,
  form: { touched, errors },
  label,
  labelClassName,
  addonText,
  rowLayout = true,
  relaxed = false,
  ...props
}) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)
  const { value: formValue, ...passField } = field
  let value
  if (relaxed && (formValue === undefined || formValue === null)) {
    value = ''
  } else {
    value = formValue
  }

  let inputElement = (
    <Input value={value} {...passField} {...props} invalid={touch && !!error} />
  )

  let feedbackElement = touch && error && (
    <FormFeedback className="d-block">{error}</FormFeedback>
  )

  if (addonText) {
    inputElement = (
      <InputGroup>
        {inputElement}
        <InputGroupText className="bg-transparent">{addonText}</InputGroupText>
      </InputGroup>
    )
  }

  return (
    <FormGroup row={rowLayout}>
      {label && (
        <Label
          sm={rowLayout ? 2 : undefined}
          className={classNames('text-left', labelClassName)}
        >
          {label}
        </Label>
      )}
      {rowLayout && (
        <Col sm={10}>
          {inputElement}
          {feedbackElement}
        </Col>
      )}
      {!rowLayout && (
        <React.Fragment>
          {inputElement}
          {feedbackElement}
        </React.Fragment>
      )}
    </FormGroup>
  )
}

export const FileField = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
  addonText,
  onChange = (e) => setFieldValue(field.name, e.target.files[0]),
  rowLayout = true,
  ...props
}) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)

  // const value = field.value ? field.value.name : ''

  let inputElement = (
    <Input
      {...field}
      {...props}
      value={undefined}
      onChange={onChange}
      invalid={touch && !!error}
    />
  )

  let feedbackElement = touch && error && (
    <FormFeedback className="d-block">{error}</FormFeedback>
  )

  if (addonText) {
    inputElement = (
      <InputGroup>
        {inputElement}
        <InputGroupText className="bg-transparent">{addonText}</InputGroupText>
      </InputGroup>
    )
  }

  return (
    <FormGroup row={rowLayout}>
      {label && (
        <Label sm={rowLayout ? 2 : undefined} className="text-left">
          {label}
        </Label>
      )}
      {rowLayout && (
        <Col sm={10}>
          {inputElement}
          {feedbackElement}
        </Col>
      )}
      {!rowLayout && (
        <React.Fragment>
          {inputElement}
          {feedbackElement}
        </React.Fragment>
      )}
    </FormGroup>
  )
}

export const ReadOnlyInput = ({
  label,
  value,
  addonText,
  feedbackText,
  rowLayout = false,
  ...props
}) => {
  const inputValue = value === null || value === undefined ? '' : value

  let inputElement = (
    <Input {...props} value={inputValue} disabled invalid={false} />
  )

  let feedbackElement = feedbackText && (
    <FormFeedback className="d-block">{feedbackText}</FormFeedback>
  )

  if (addonText) {
    inputElement = (
      <InputGroup>
        {inputElement}
        <InputGroupText className="bg-transparent">{addonText}</InputGroupText>
      </InputGroup>
    )
  }

  return (
    <FormGroup row={rowLayout} className="field-qr-form-readonly">
      {label && (
        <Label sm={rowLayout ? 4 : undefined} className="text-left">
          {label}
        </Label>
      )}
      {rowLayout && (
        <Col sm={8}>
          {inputElement}
          {feedbackElement}
        </Col>
      )}
      {!rowLayout && (
        <React.Fragment>
          {inputElement}
          {feedbackElement}
        </React.Fragment>
      )}
    </FormGroup>
  )
}

export const InputField2 = ({
  field,
  form: { touched, errors },
  label,
  addonText,
  rowLayout = false,
  ...props
}) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)

  let inputElement = <Input {...field} {...props} invalid={touch && !!error} />

  let feedbackElement = touch && error && (
    <FormFeedback className="d-block">{error}</FormFeedback>
  )

  if (addonText) {
    inputElement = (
      <InputGroup>
        {inputElement}
        <InputGroupText className="bg-transparent">{addonText}</InputGroupText>
      </InputGroup>
    )
  }

  return (
    <FormGroup row={rowLayout}>
      {label && (
        <Label sm={rowLayout ? 4 : undefined} className="text-left">
          {label}
        </Label>
      )}
      {rowLayout && (
        <Col sm={8}>
          {inputElement}
          {feedbackElement}
        </Col>
      )}
      {!rowLayout && (
        <React.Fragment>
          {inputElement}
          {feedbackElement}
        </React.Fragment>
      )}
    </FormGroup>
  )
}

export const CheckboxField = ({
  field,
  label,
  form,
  checked,
  className,
  onChange,
  ...props
}) => {
  let isChecked
  if (typeof checked === 'boolean') {
    isChecked = checked
  } else {
    isChecked = field.value
  }
  return (
    <FormGroup check className={className}>
      <Label check>
        <Input
          {...props}
          type="checkbox"
          {...omit(field, 'value')}
          onChange={(e) => {
            onChange && onChange(e)
            field.onChange(e)
          }}
          checked={isChecked}
        />{' '}
        {label}
      </Label>
    </FormGroup>
  )
}

export const SwtichField = ({
  field,
  label,
  form,
  checked,
  className,
  onChange,
  ...props
}) => {
  let isChecked
  if (typeof checked === 'boolean') {
    isChecked = checked
  } else {
    isChecked = field.value
  }
  return (
    <FormGroup row className={className}>
      <Col sm={2}>
        <Switch
          checked={isChecked}
          onChange={(checked) => form.setFieldValue(field.name, checked)}
          onColor="#86d3ff"
          onHandleColor="#2693e6"
          handleDiameter={20}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
          height={20}
          width={40}
          {...props}
        />
      </Col>
      <Col sm={10}>
        <Label>{label}</Label>
      </Col>
    </FormGroup>
  )
}

export const MultiSelectField = ({
  field,
  label,
  form,
  checked,
  className,
  onChange,
  options,
  ...props
}) => {
  const { value: flatValue, onBlur, name } = field
  const value = useMemo(() => {
    const optionsById = keyBy(options, 'id')
    return flatValue.reduce((selection, id) => {
      const item = optionsById[id]
      if (item) {
        selection.push(item)
      }
      return selection
    }, [])
  }, [flatValue, options])

  return (
    <FormGroup row className={className}>
      <Col sm={2}>
        <Label>{label}</Label>
      </Col>
      <Col sm={10}>
        <Select
          {...props}
          getOptionValue={(o) => o.id}
          isMulti
          onChange={(selection) => {
            form.setFieldValue(
              name,
              selection.map((s) => s.id)
            )
          }}
          options={options}
          onBlur={onBlur}
          value={value}
        />
      </Col>
    </FormGroup>
  )
}

export const SelectField = ({ label, ...props }) => {
  const [field, meta, { setValue, setTouched }] = useField(props)
  const options = props.children.map((option) => ({
    value: option.props.value,
    label: option.props.children,
  }))

  const onChange = ({ value }) => {
    setValue(value)
  }

  return (
    <FormGroup row className={props.className}>
      <Col sm={2}>
        <Label>{label}</Label>
      </Col>
      <Col sm={10}>
        <CreatableSelect
          getOptionValue={(o) => o.value}
          value={options.find((option) => option.value === field.value.toString())}
          options={options}
          isClearable
          placeholder={props.placeholder}
          formatCreateLabel={props.formatCreateLabel}
          onChange={onChange}
          onBlur={setTouched}
        />
        {meta.touched && meta.error ? (
        <div className="form-text text-danger">{meta.error}</div>
      ) : null}
      </Col>
    </FormGroup>
  )
}

