import { Route, Routes } from 'react-router'
import PageNotFound from '../../components/PageNotFound'
import GroupDetail from './GroupDetail'
import GroupsList from './GroupsList'

export default function Groups() {
  return (
    <Routes>
      <Route index element={<GroupsList />} />
      <Route path=":id" element={<GroupDetail />} />
      <Route path="new" element={<GroupDetail />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}
