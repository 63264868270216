import { Link } from 'react-router-dom'
import UserForm from '../../components/UserForm'
import { useCreateUser } from '../../hooks/users'

export default function NewUser() {
  const { run: createUser } = useCreateUser()[1]
  return (
    <div data-test="page-users-add">
      <div className="page-header mx-4">
        <h3>Aggiungi Utente</h3>
      </div>
      <div className="page-toolbar d-flex justify-content-between align-items-center pt-3 pb-3 mx-4">
        <div className="page-breadcrumb">
          <Link className="text-decoration-none" to="/users">
            Gestione Utenti
          </Link>{' '}
          / Aggiungi Utente
        </div>
      </div>
      <UserForm mode="create" save={(data) => createUser.asPromise(data)} />
    </div>
  )
}
