import { Field, Form, Formik } from 'formik'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import * as Yup from 'yup'
import { InputField } from '../../components/Fields'
import { transformErrorForForm } from '../../components/Fields/utils'
import { useRecoverPassword } from '../../hooks/recover'

const RecoverSchema = Yup.object().shape({
  email: Yup.string().email().required().label('Email'),
})

export default function Recover() {
  const [{ data: recovered }, { run }] = useRecoverPassword()

  return (
    <div>
      {recovered && (
        <div className="alert alert-success alert-login">
          Email inviata. Controlla la tua mail o ritorna al Login.
        </div>
      )}
      <div className="form-recover">
        <h3 className='login'>Recupera Password</h3>
        <Formik
          validationSchema={RecoverSchema}
          initialValues={{
            email: '',
          }}
          onSubmit={(values, actions) =>
            run
              .asPromise({
                email: values.email,
              })
              .catch((err) => {
                actions.setErrors(transformErrorForForm(err))
              })
          }
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <Field
                isRequired
                type="email"
                label="Email"
                name="email"
                className="form-control"
                component={InputField}
              />
              <div>Inserisci l’email a cui invieremo la Password.</div>
              <Button
                className="w-100 mt-4"
                color="primary"
                disabled={isSubmitting || recovered}
                type="submit"
              >
                Invia Email
              </Button>
            </Form>
          )}
        </Formik>
        <Link className="text-decoration-none" to={'/'}>
          <div className="mt-3 text-end text-primary">Torna al Login</div>
        </Link>
      </div>{' '}
    </div>
  )
}
