import { Field, Formik } from 'formik'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useState } from 'react'
import { Button, Form } from 'reactstrap'
import { useAuthActions } from 'use-eazy-auth'
import { ReactComponent as IconEdit } from '../../assets/icons/icon-edit.svg'
import { AutoAlert } from '../../components/AutoAlert'
import { InputField } from '../../components/Fields'
import { transformErrorForForm } from '../../components/Fields/utils'
import { useUpdatePassword, useUpdateProfile } from '../../hooks/profile'
import { ChangePasswordModal } from './ChangePasswordModal'

export function ProfileUser({ user }) {
  const [editable, setEditable] = useState(false)
  const { updateUser } = useAuthActions()
  const [, { run: updateProfile }] = useUpdateProfile()
  const [, { run: updatePassword }] = useUpdatePassword()

  const [passwordModalState, passwordModalActions] = useModalTrigger()

  console.log(user)

  return (
    <div className="row" data-test="profile-user">
      <div className="offset-md-1 col-md-10">
        <div className="flex-1 d-flex flex-column justify-content-start align-items-stretch">
          <div className="page-header mx-4 d-flex flex-row justify-content-between align-items-center">
            <h3>Profilo Utente</h3>
            {!editable && (
              <Button
                outline
                color="igp-blue"
                size="sm"
                className="px-4 d-inline-flex flex-row align-items-center"
                onClick={() => {
                  setEditable((v) => !v)
                }}
                data-test="edit"
              >
                <IconEdit className="me-1" />
                {' Modifica'}
              </Button>
            )}
          </div>
          <AutoAlert>
            <div style={{ height: 60 }} />
          </AutoAlert>
          <div className="pt-4 px-4 flex-1 d-flex flex-column justify-content-start">
            <Formik
              initialValues={{
                name: user.name,
                surname: user.surname,
                email: user.email,
                email_consent: user.email_consent,
              }}
              enableReinitialize
              onSubmit={(values, actions) => {
                return updateProfile
                  .asPromise(values)
                  .catch((error) =>
                    actions.setErrors(transformErrorForForm(error))
                  )
                  .then((result) => {
                    setEditable(false)
                    updateUser(result)
                  })
              }}
            >
              {(formik) => {
                return (
                  <Form
                    onSubmit={formik.handleSubmit}
                    className="flex-1 d-flex flex-column justify-content-start align-items-stretch"
                  >
                    <div className="col-12 col-lg-6">
                      <Field
                        name="name"
                        label="Nome"
                        component={InputField}
                        plaintext={!editable}
                        disabled={!editable}
                      />
                      <Field
                        name="surname"
                        label="Cognome"
                        component={InputField}
                        plaintext={!editable}
                        disabled={!editable}
                      />
                      <Field
                        name="email"
                        label="Email"
                        component={InputField}
                        plaintext={!editable}
                        disabled={!editable}
                        type="email"
                      />
                      <div
                        role="group"
                        aria-labelledby="my-radio-group"
                        className="mb-2"
                      >
                        <span>
                          Acconsento l’invio di comunicazioni di carattere
                          informativo/commerciale/promozionale relative all’Area
                          Riservata di IGPDecaux.
                        </span>
                        <br />
                        <div className="mt-1">
                          <label>
                            <Field
                              disabled={!editable}
                              type="radio"
                              onChange={() =>
                                formik.setFieldValue('email_consent', true)
                              }
                              name="email_consent"
                              checked={formik.values.email_consent}
                              className={'mx-1'}
                            />
                            Si
                          </label>
                          <label className="mx-2">
                            <Field
                              type="radio"
                              disabled={!editable}
                              onChange={() =>
                                formik.setFieldValue('email_consent', false)
                              }
                              name="email_consent"
                              checked={
                                !formik.values.email_consent &&
                                formik.values.email_consent !== ''
                              }
                              className={'mx-1'}
                            />
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                    {!editable && (
                      <div style={{ paddingTop: 60 }}>
                        <Button
                          color="igp-blue"
                          size="sm"
                          className="px-4 d-inline-flex flex-row align-items-center"
                          onClick={() => {
                            passwordModalActions.open('dummy')
                          }}
                          data-test="change-password"
                        >
                          <IconEdit className="me-1" />
                          {' Modifica password'}
                        </Button>
                      </div>
                    )}
                    <div className="flex-1" />
                    {editable && (
                      <div className="pb-4 d-flex flex-row justify-content-between align-items-stretch">
                        <Button
                          outline
                          color="igp-blue"
                          onClick={() => {
                            setEditable(false)
                            formik.resetForm()
                          }}
                        >
                          Annulla
                        </Button>
                        <Button color="igp-blue" type="submit">
                          Salva
                        </Button>
                      </div>
                    )}
                    <div className="mt-5 text-center">
                      Utilizzando l’Applicazione si dichiara di aver letto e
                      accettato l’
                      <a
                        target={'_blank'}
                        rel="noreferrer"
                        href={user.public_file ? user.public_file : ''}
                      >
                        Informativa sul Trattamento dei Dati Personali
                      </a>
                      {'. '}
                      <br />
                      Per eliminare l’Account inviare un’email a{' '}
                      <a href="mailto:unsubscribe@igpdecaux.it">
                        unsubscribe@igpdecaux.it
                      </a>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
          {passwordModalState.value && (
            <ChangePasswordModal
              isOpen={passwordModalState.isOpen}
              onClosed={passwordModalActions.onClosed}
              onCancel={passwordModalActions.close}
              onSubmit={(values) => {
                return updatePassword
                  .onSuccess(() => {
                    passwordModalActions.close()
                  })
                  .asPromise(values)
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}
