import { useState } from 'react'
import { useGroupsList } from '../../hooks/groups'
import { Link, useSearchParams } from 'react-router-dom'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useNavigate } from 'react-router'
import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import {
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap'
import { ReactComponent as IconAdd } from '../../assets/icons/icon-add.svg'
import { ReactComponent as IconTrash } from '../../assets/icons/icon-trash-white.svg'
import classNames from 'classnames'
import omit from 'lodash/omit'
import { AutoAlert } from '../../components/AutoAlert'

export default function GroupsList() {
  const [searchInput, setSearchInput] = useState('')
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const filters = useMemoCompare({
    search: searchParams.get('search') ?? '',
  })
  const [selected, setSelected] = useState({})
  const [modalDelete, actionsModalDelete] = useModalTrigger()

  const atLeastOneSelected = Object.keys(selected).length > 0

  const [{ data: groups }, { bulkDelete }] = useGroupsList(filters)

  if (!groups) {
    return (
      <div className="d-flex justify-content-center w-100 align-items-center text-center spinner-container">
        <Spinner />
      </div>
    )
  }

  return (
    <div data-test="page-groups-list">
      <AutoAlert>
        <div className="page-header mx-4">
          <h3>Gestione Gruppi</h3>
        </div>
      </AutoAlert>
      {!atLeastOneSelected && (
        <div className="page-toolbar d-flex flex-row justify-content-between pt-3 pb-3 mx-4">
          <Form
            className="d-flex flex-row justify-content-start align-items-center"
            onSubmit={(e) => {
              e.preventDefault()
              setSearchParams({ ...filters, search: searchInput })
              return false
            }}
          >
            <Input
              bsSize="sm"
              placeholder="Cerca"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            ></Input>
            <Button
              outline
              color="igp-blue"
              size="sm"
              className="ms-2"
              onClick={(e) =>
                setSearchParams({ ...filters, search: searchInput })
              }
            >
              Cerca
            </Button>
          </Form>
          <Button
            onClick={() => navigate('/groups/new')}
            color="igp-blue"
            size="sm"
            className="px-3"
            data-test="add"
          >
            <IconAdd />
            {' Aggiungi'}
          </Button>
        </div>
      )}
      {atLeastOneSelected && (
        <div className="page-toolbar d-flex flex-row justify-content-end pt-3 pb-3 mx-4">
          <Form className="d-flex flex-row justify-content-start align-items-center">
            <Button
              outline
              color="danger"
              size="sm"
              className="me-2"
              onClick={(e) => setSelected({})}
            >
              Annulla
            </Button>
          </Form>
          <Button
            color="danger"
            size="sm"
            className="px-3"
            onClick={() => {
              actionsModalDelete.open(selected)
            }}
            data-test="delete"
          >
            <IconTrash />
            {' Elimina'}
          </Button>
        </div>
      )}

      <div className="pt-3">
        {groups?.map((group, i) => (
          <div
            key={group.id}
            className={classNames('pt-2 pb-3 px-4', {
              'bg-light': i % 2 !== 1 && !selected[group.id],
              'bg-igp-focus': selected[group.id],
            })}
          >
            <FormGroup check inline className="mb-0">
              <Input
                type="checkbox"
                checked={selected[group.id] ?? false}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelected((prev) => ({ ...prev, [group.id]: true }))
                  } else {
                    setSelected((prev) => omit(prev, group.id))
                  }
                }}
                data-test="checkbox"
              />
              <Link
                to={`/groups/${group.id}`}
                className="mb-0 text-primary fw-500"
                style={{ textDecoration: 'none' }}
              >
                {group.name}
              </Link>
            </FormGroup>
            <div style={{ paddingLeft: 25, maxWidth: '50%', lineHeight: 1 }}>
              <small>{group.description}</small>
            </div>
          </div>
        ))}
      </div>
      {atLeastOneSelected && (
        <Modal
          isOpen={modalDelete.isOpen}
          toggle={actionsModalDelete.toggle}
          size="md"
          centered
          data-test="modal-delete"
        >
          <ModalHeader toggle={actionsModalDelete.toggle}>Elimina</ModalHeader>
          <ModalBody>Sei sicuro di eliminare i gruppi selezionati?</ModalBody>
          <ModalFooter className="d-flex justify-content-between">
            <Button
              outline
              color="danger"
              size="sm"
              className="me-2"
              onClick={(e) => {
                setSelected({})
                actionsModalDelete.close()
              }}
            >
              Annulla
            </Button>
            <Button
              color="danger"
              size="sm"
              className="px-3"
              onClick={() => {
                bulkDelete(
                  Array.from(Object.keys(selected)).map((n) => parseInt(n, 10))
                )
                setSelected({})
                actionsModalDelete.close()
              }}
            >
              <IconTrash />
              {' Elimina'}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  )
}
