import { deps, rj, useRunRj } from 'react-rocketjump'
import api from '../api'

const PoliciesListState = rj({
  name: 'policiesList',
  effectCaller: 'configured',
  effect:
    (t) =>
    (params = {}) => {
      return api.auth(t).get('/api/policies', params)
    },
  mutations: {
    bulkDelete: rj.mutation.single({
      effect: (token) => (ids) => {
        return api
          .auth(token)
          .mapResponse(() => ids)
          .post('/api/policies/bulk_delete/', { ids })
      },
      updater: (state, result) => {
        return {
          ...state,
          data: state.data.filter((datum) => !result.includes(datum.id)),
        }
      },
    }),
  },
})

const NO_PARAMS = {}
export function usePoliciesList(params = NO_PARAMS) {
  return useRunRj(PoliciesListState, [deps.maybe(params)], false)
}

const PoliciesState = rj({
  name: 'policyDetail',
  effectCaller: 'configured',
  effect: (t) => (id) => {
    return api.auth(t).get(`/api/policies/${id}`)
  },
  mutations: {
    update: rj.mutation.single({
      effect: (token) => (id, data) => {
        return api.auth(token).patch(`/api/policies/${id}/`, data)
      },
      updater: 'updateData',
    }),
    create: rj.mutation.single({
      effect: (token) => (data) => {
        return api.auth(token).post(`/api/policies/`, data)
      },
      updater: 'updateData',
    }),
    delete: rj.mutation.single({
      effect: (token) => (id) => {
        return api.auth(token).delete(`/api/policies/${id}/`)
      },
      updater: 'updateData',
    }),
  },
})

export function usePolicy(id) {
  return useRunRj(PoliciesState, [deps.maybe(id)], true)
}
