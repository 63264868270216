import { Outlet } from 'react-router'
import TopBar from './TopBar'

export default function Layout() {
  return (
    <div>
      <div>
        <TopBar />
      </div>
      <div className="h-100">
        <Outlet />
      </div>
    </div>
  )
}
