import { Field, Formik } from 'formik'
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import { InputField } from '../../components/Fields'
import { transformErrorForForm } from '../../components/Fields/utils'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  old_password: yup.string().required(),
  password: yup
    .string()
    .required()
    .oneOf([yup.ref('password2'), null], 'Le due password non sono uguali'),
  password2: yup
    .string()
    .required()
    .oneOf([yup.ref('password'), null], 'Le due password non sono uguali'),
})

export function ChangePasswordModal({ isOpen, onClosed, onCancel, onSubmit }) {
  return (
    <Modal isOpen={isOpen} toggle={onCancel} onClosed={onClosed} data-test="modal-change-password">
      <Formik
        initialValues={{
          old_password: '',
          password: '',
          password2: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          onSubmit(values).catch((error) =>
            actions.setErrors(transformErrorForForm(error))
          )
        }}
      >
        {(formik) => (
          <>
            <ModalHeader toggle={onCancel}>Modifica Password</ModalHeader>
            <ModalBody>
              <Form onSubmit={formik.handleSubmit}>
                <FormGroup>
                  <Label>Password Corrente</Label>
                  <Field
                    name="old_password"
                    autoComplete="current-password"
                    component={InputField}
                    type="password"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Nuova Password</Label>
                  <Field
                    name="password"
                    component={InputField}
                    autoComplete="new-password"
                    type="password"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Ripeti Nuova password</Label>
                  <Field
                    name="password2"
                    component={InputField}
                    type="password"
                  />
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter className="d-flex flex-row justify-content-between align-items-center">
              <Button size="sm" color="igp-blue" outline onClick={onCancel}>
                Annulla
              </Button>
              <Button
                size="sm"
                color="igp-blue"
                onClick={() => {
                  formik.submitForm()
                }}
              >
                Salva
              </Button>
            </ModalFooter>
          </>
        )}
      </Formik>
    </Modal>
  )
}
