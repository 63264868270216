import { useAuthUser } from 'use-eazy-auth'
import { ProfileAdmin } from './ProfileAdmin'
import { ProfileUser } from './ProfileUser'

export default function Profile() {
  const { user } = useAuthUser()

  if (user.administrator) {
    return <ProfileAdmin user={user} />
  } else {
    return <ProfileUser user={user} />
  }
}
