import { Link } from 'react-router-dom'
import logo from '../assets/logo.png'
import { ReactComponent as IconPerson } from '../assets/icons/icon-person.svg'
import { useAuthUser, useAuthActions } from 'use-eazy-auth'

export default function TopBar() {
  const { user } = useAuthUser()
  const { logout } = useAuthActions()

  return (
    <div className="topbar d-flex align-items-center justify-content-between flex-row px-3 border-bottom">
      <div>
        <Link to='/'><img src={logo} className='logo' alt="logo igp"></img></Link>
      </div>
      <div>
        {user && (
          <>
            <Link
              to="/profile"
              className="btn btn-link text-decoration-none border-end email-topbar"
              data-test="profile"
            >
              {user.email}
            </Link>
            <Link className='email-topbar-mobile' to="/profile">
              <IconPerson />
            </Link>
            <span
              className="btn btn-link text-decoration-none logout-button"
              type="button"
              onClick={logout}
            >
              Logout
            </span>
          </>
        )}
      </div>
    </div>
  )
}
