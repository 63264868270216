import { deps, rj, useRunRj } from 'react-rocketjump'
import api from '../api'

const GroupsListState = rj({
  name: 'groupsList',
  effectCaller: 'configured',
  effect:
    (t) =>
    (params = {}) => {
      return api.auth(t).get('/api/groups', params)
    },
  mutations: {
    bulkDelete: rj.mutation.single({
      effect: (token) => (ids) => {
        return api
          .auth(token)
          .mapResponse(() => ids)
          .post('/api/groups/bulk_delete/', { ids })
      },
      updater: (state, result) => {
        return {
          ...state,
          data: state.data.filter((datum) => !result.includes(datum.id)),
        }
      },
    }),
  },
})

const NO_PARAMS = {}
export function useGroupsList(params = NO_PARAMS) {
  return useRunRj(GroupsListState, [deps.maybe(params)], false)
}

const GroupsState = rj({
  name: 'groupDetail',
  effectCaller: 'configured',
  effect: (t) => (id) => {
    return api.auth(t).get(`/api/groups/${id}`)
  },
  mutations: {
    update: rj.mutation.single({
      effect: (token) => (id, data) => {
        return api.auth(token).patch(`/api/groups/${id}/`, data)
      },
      updater: 'updateData',
    }),
    create: rj.mutation.single({
      effect: (token) => (data) => {
        return api.auth(token).post(`/api/groups/`, data)
      },
      updater: 'updateData',
    }),
    delete: rj.mutation.single({
      effect: (token) => (id) => {
        return api.auth(token).delete(`/api/groups/${id}`)
      },
      updater: 'updateData',
    }),
  },
})

export function useGroup(id) {
  return useRunRj(GroupsState, [deps.maybe(id)], true)
}
