import { rj, useRj, useRunRj } from 'react-rocketjump'
import api from '../api'

const CheckResetTokenState = rj().effect({
  name: 'CheckResetToken',
  effect: (token) =>
    api.mapResponse(() => true).get(`/api/check-reset-token/${token}/`),
})

export function useCheckResetToken(token) {
  return useRunRj(CheckResetTokenState, [token])
}

const ResetPasswordState = rj().effect({
  name: 'ResetPassword',
  effect: (token, data) =>
    api.mapResponse(() => true).post(`/api/reset-password/${token}/`, data),
})

export function useResetPassword() {
  return useRj(ResetPasswordState)
}
