import classNames from 'classnames'
import { Link } from 'react-router-dom'
import * as dayjs from 'dayjs'
import { useFoldersList } from '../../hooks/folders'

export default function FolderListUser() {
  const [{ data: folders }] = useFoldersList()

  return (
    <div className="container-fluid" data-test="page-folders-list">
      <div className="row">
        <div className="offset-md-1 col-md-10">
          <div className="page-header mx-4">
            <h3>Documenti</h3>
          </div>
          <div className="pt-3 pb-3 mx-4">
            <div className="page-breadcrumb">Home</div>
          </div>
        </div>
        <div>
          <div className="pt-3">
            {folders?.content_folders?.map((content, i) => (
              <div
                key={content.id}
                className={classNames('pt-3 pb-3 row', {
                  'bg-light': i % 2 !== 1,
                })}
              >
                <div className="offset-md-1 col-md-10  container-block-files">
                  <div className="d-flex block-files-user mx-4">
                    <div>
                      <span
                        className={`fiv-viv fiv-icon-folder fiv-size-xs icon-folder`}
                      />
                    </div>
                    <div className="mx-2">
                      <Link
                        to={`/file-browser/${content.id}`}
                        className="mb-0 text-primary fw-500 text-decoration-none"
                      >
                        {content.name}
                      </Link>
                      <div>
                        <small>{content.description}</small>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="info-folders">
                      <div>{content.content_count} files</div>
                      <div className="mx-5">
                        {dayjs(content.created_at).format('DD/MM/YYYY')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
