import { Field, Formik } from 'formik'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { ReactComponent as IconTrash } from '../../assets/icons/icon-trash-white.svg'
import * as Yup from 'yup'
import { InputField } from '../../components/Fields'
import { transformErrorForForm } from '../../components/Fields/utils'
import { useGroup } from '../../hooks/groups'

const GROUP_INITIAL = {
  name: '',
  description: '',
}

const SCHEMA_GROUP = Yup.object().shape({
  name: Yup.string().required().label('Nome'),
  description: Yup.string().required().label('Descrizione'),
})

const placeHolderString =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in cras arcu amet enim justo, massa turpis.'

function GroupDetailSafe({
  group,
  handleUpdate,
  handleCreate,
  handleAbort,
  navigate,
  actionsModalDelete,
}) {
  return (
    <div data-test="page-groups-add">
      <div className="page-header mx-4">
        {group.id && <h3>{group.name}</h3>}
        {!group.id && <h3>Nuovo gruppo</h3>}
      </div>
      <div className="page-toolbar d-flex justify-content-between align-items-center pt-3 pb-3 mx-4">
        <div className="page-breadcrumb">
          <Link className="text-decoration-none" to="/groups">
            Gestione Gruppi
          </Link>{' '}
          / {group.id ? group.name : 'Nuovo Gruppo'}
        </div>
        {group.id && (
          <Button
            color="danger"
            size="sm"
            className="px-3"
            onClick={() => {
              actionsModalDelete.open(group)
            }}
            data-test="delete"
          >
            <IconTrash />
            {' Elimina'}
          </Button>
        )}
      </div>
      <Formik
        initialValues={group}
        validationSchema={SCHEMA_GROUP}
        onSubmit={(values, actions) => {
          if (group.id) {
            return handleUpdate
              .onSuccess((group) =>
                navigate('/groups', {
                  state: {
                    message: `Gruppo ${group.name} aggiornato con successo`,
                  },
                })
              )
              .asPromise(group.id, values)
              .catch((error) => actions.setErrors(transformErrorForForm(error)))
          } else {
            return handleCreate
              .onSuccess((group) =>
                navigate('/groups', {
                  state: {
                    message: `Gruppo ${group.name} aggiunto con successo`,
                  },
                })
              )
              .asPromise(values)
              .catch((error) => actions.setErrors(transformErrorForForm(error)))
          }
        }}
      >
        {(formik) => (
          <div className="mx-4 mt-5">
            <Form
              onSubmit={formik.handleSubmit}
              className="flex-1 d-flex flex-column justify-content-start align-items-stretch"
            >
              <div className="col-md-8">
                <Field name="name" label="Nome" component={InputField} />
                <Field
                  name="description"
                  label="Descrizione"
                  placeholder={!group.id ? placeHolderString : ''}
                  component={InputField}
                  rows={6}
                  type="textarea"
                />
              </div>
              <div className="flex-1" />
              <div className="d-flex mt-5 flex-row justify-content-between align-items-center">
                <Button outline color="igp-blue" onClick={handleAbort}>
                  Annulla
                </Button>
                <Button color="igp-blue" type="submit">
                  Salva
                </Button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  )
}

export default function GroupDetail() {
  const { id: groupId } = useParams()
  const [{ data: group }, { update, create, delete: deleteGroup }] =
    useGroup(groupId)
  const navigate = useNavigate()

  const [modalDelete, actionsModalDelete] = useModalTrigger()

  if (groupId && !group) {
    return (
      <div className="text-center">
        <Spinner />
      </div>
    )
  }

  return (
    <>
      <GroupDetailSafe
        group={group ?? GROUP_INITIAL}
        handleUpdate={update}
        actionsModalDelete={actionsModalDelete}
        handleCreate={create}
        navigate={navigate}
        handleAbort={() => navigate(-1)}
      />
      {group && (
        <Modal
          isOpen={modalDelete.isOpen}
          toggle={actionsModalDelete.toggle}
          size="md"
          centered
          data-test="modal-delete"
        >
          <ModalHeader toggle={actionsModalDelete.toggle}>Elimina</ModalHeader>
          <ModalBody>Sei sicuro di eliminare il gruppo {group.name}?</ModalBody>
          <ModalFooter className="d-flex justify-content-between">
            <Button
              outline
              color="danger"
              size="sm"
              className="me-2"
              onClick={() => {
                actionsModalDelete.close()
              }}
            >
              Annulla
            </Button>
            <Button
              color="danger"
              size="sm"
              className="px-3"
              onClick={() => {
                deleteGroup
                  .onSuccess(() => {
                    actionsModalDelete.close()
                    navigate('/groups')
                  })
                  .asPromise(group.id)
              }}
            >
              <IconTrash />
              {' Elimina'}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
