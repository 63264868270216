import { useMemo } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import * as dayjs from 'dayjs'
import { useFoldersList } from '../../hooks/folders'
import { ReactComponent as IconDownload } from '../../assets/icons/icon-download.svg'
import classNames from 'classnames'
import IconFile from '../../components/IconFile'
import Breadcrumb from '../../components/Breadcrumb'

export default function FolderContentUser() {
  const { id: folderId } = useParams()
  const [modalDownload, actionsModalDownload] = useModalTrigger()

  const filters = useMemo(() => {
    if (folderId) {
      return {
        folderId,
      }
    }
  }, [folderId])

  const [{ data: folderContent }] = useFoldersList(filters)

  if (folderId && !folderContent) {
    return (
      <div className="d-flex justify-content-center w-100 align-items-center text-center spinner-container">
        <Spinner />
      </div>
    )
  }

  return (
    <div className='container-fluid' data-test="page-folder-content">
      <div className="row">
        <div className="offset-md-1 col-md-10">
          <div className="page-header mx-4">
            <h3>Gestione file</h3>
          </div>
          <div className="pt-3 pb-3 mx-4">
            {folderContent.folder && (
              <div className="page-breadcrumb">
                <Breadcrumb isUser={true} path={folderContent.path} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className="pt-3">
          {folderContent?.content_folders?.map((content, i) => (
            <div
              key={content.id}
              className={classNames('pt-3 pb-3 position-relative row', {
                'bg-light': i % 2 !== 1,
              })}
            >
              <div className="container-block-files offset-md-1 col-md-10">
                <div
                  className="d-flex block-files-user mx-4"
                  style={{ width: '50%', marginRight: 50 }}
                >
                  <div>
                    <span
                      className={`fiv-viv fiv-icon-folder fiv-size-xs mx-2 icon-folder`}
                    />
                  </div>
                  <div className="mx-1">
                    <Link
                      to={`/file-browser/${content.id}`}
                      className="text-primary"
                      style={{ textDecoration: 'none' }}
                    >
                      {content.name}
                    </Link>

                    <div>
                      <small>{content.description}</small>
                      <div className="info-folders px-0">
                        <div style={{ whiteSpace: 'nowrap' }}>
                          {content.content_count} files
                        </div>
                        <div className="mx-5">
                          {dayjs(content.created_at).format('DD/MM/YYYY')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {folderContent?.content_files?.map((content, i) => (
            <div
              key={content.id}
              className={classNames('pt-3 pb-3 position-relative row', {
                'bg-light': i % 2 !== 1,
              })}
            >
              <div className="offset-md-1 col-md-10 container-block-files">
                <div className="d-flex block-files-user mx-4">
                  <div
                    className="mb-0 fw-500"
                    style={{ textDecoration: 'none' }}
                  >
                    <IconFile file={content.file} />
                  </div>

                  <div className="mx-1">
                    <span
                      className="text-primary pointer"
                      onClick={() => actionsModalDownload.open(content)}
                    >
                      {content.display_name}
                    </span>
                    <div>
                      <small>{content.description}</small>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="info-files">
                    <div style={{ width: 50 }}>{content.size}</div>
                    <div className="mx-5">
                      {dayjs(content.created_at).format('DD/MM/YYYY')}
                    </div>
                  </div>
                  <div className="icon-download">
                    <a target={'_blank'} rel="noreferrer" href={content.file}>
                      <IconDownload />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {modalDownload.value && (
        <Modal
          centered
          isOpen={modalDownload.isOpen}
          toggle={() => actionsModalDownload.toggle()}
        >
          <ModalHeader toggle={() => actionsModalDownload.toggle()}>
            Scarica File
          </ModalHeader>
          <ModalBody>
            Scarico il file {modalDownload.value.display_name}?
          </ModalBody>
          <ModalFooter className="d-flex justify-content-between">
            <Button
              color="igp-blue"
              outline
              onClick={() => actionsModalDownload.toggle()}
            >
              Annulla
            </Button>
            <a
              target={'_blank'}
              className="btn btn-igp-blue"
              rel="noreferrer"
              onClick={() => actionsModalDownload.toggle()}
              href={modalDownload.value.file}
            >
              {' '}
              Scarica
            </a>
          </ModalFooter>
        </Modal>
      )}
    </div>
  )
}
