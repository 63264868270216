import { Field, Form, Formik } from 'formik'
import { useParams } from 'react-router'
import { Button } from 'reactstrap'
import { useAuthActions } from 'use-eazy-auth'
import * as Yup from 'yup'
import { CheckboxField, InputField } from '../../components/Fields'
import { transformErrorForForm } from '../../components/Fields/utils'
import {
  useActivateUser,
  useCheckActivateUserToken,
} from '../../hooks/activateUser'

const ActivationSchema = Yup.object().shape({
  password: Yup.string().required().label('Password'),
  repeatPassword: Yup.string()
    .required()
    .label('Conferma password')
    .oneOf([Yup.ref('password'), null], 'Le password non corrispondono.'),
})

export default function ActivationUser() {
  const { token } = useParams()
  const { login } = useAuthActions()
  const [{ data: user }] = useCheckActivateUserToken(token)
  const [, { run: handleActivateUser }] = useActivateUser()

  return (
    <div>
      <div className="form-recover" style={{ top: '20%' }}>
        <h3 className="login">Attivazione utente</h3>
        <Formik
          validationSchema={ActivationSchema}
          enableReinitialize
          initialValues={{
            email: user ? user.email : '',
            accetto_condizioni: false,
            email_consent: '',
          }}
          onSubmit={(values, actions) =>
            handleActivateUser
              .onSuccess(() => {
                login({ email: user.email, password: values.password })
              })
              .asPromise(token, values)
              .catch((err) => actions.setErrors(transformErrorForForm(err)))
          }
        >
          {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Field
                type="text"
                disabled
                rowLayout={false}
                label="Email"
                name="email"
                className={'mb-1'}
                component={InputField}
              />
              <Field
                isRequired
                type="password"
                rowLayout={false}
                autoComplete="new-password"
                label="Password"
                name="password"
                component={InputField}
              />
              <Field
                isRequired
                type="password"
                rowLayout={false}
                label="Conferma password"
                name="repeatPassword"
                component={InputField}
              />
              <div
                role="group"
                aria-labelledby="my-radio-group"
                className="mb-2"
              >
                <span>
                  Acconsento l’invio di comunicazioni di carattere
                  informativo/commerciale/promozionale relative all’Area
                  Riservata di IGPDecaux.
                </span>
                <div>
                  <label>
                    <Field
                      type="radio"
                      onChange={() => setFieldValue('email_consent', true)}
                      name="email_consent"
                      checked={values.email_consent}
                      className={'mx-1'}
                    />
                    Si
                  </label>
                  <label className="mx-2">
                    <Field
                      type="radio"
                      onChange={() => setFieldValue('email_consent', false)}
                      name="email_consent"
                      checked={
                        !values.email_consent && values.email_consent !== ''
                      }
                      className={'mx-1'}
                    />
                    No
                  </label>
                </div>
              </div>
              <Field
                component={CheckboxField}
                name="accetto_condizioni"
                label={
                  <span>
                    Dichiaro di aver letto e accettato l’
                    <a
                      target={'_blank'}
                      rel="noreferrer"
                      href={user && user.public_file ? user.public_file : ''}
                    >
                      Informativa sul Trattamento dei Dati Personali
                    </a>{' '}
                  </span>
                }
              />
              <Button
                className="w-100 mt-2"
                color="primary"
                disabled={
                  isSubmitting ||
                  !values.accetto_condizioni ||
                  values.email_consent === ''
                }
                type="submit"
              >
                Accedi
              </Button>
            </Form>
          )}
        </Formik>
      </div>{' '}
    </div>
  )
}
