import { Field, Formik } from 'formik'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import {
  InputField,
  MultiSelectField,
  SelectField,
  SwtichField,
} from './Fields'
import * as Yup from 'yup'
import * as dayjs from 'dayjs'
import {
  Alert,
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import { useMemo, useState } from 'react'
import { useGroupsList } from '../hooks/groups'
import { transformErrorForForm } from './Fields/utils'
import { usePoliciesList } from '../hooks/policies'
import { useNavigate } from 'react-router'
import { useUsersList } from '../hooks/users'
import { uniqBy } from 'lodash'

const INITIAL_ADMIN_VALUES = {
  has_files_management: false,
  has_folders_management: false,
  has_policies_management: false,
  has_users_management: false,
  files_management_policies: [],
}

const INITIAL_EXTERNAL_USER_VALUES = {
  company: '',
  group: '',
  policies: [],
}

const INITIAL_VALUES = {
  name: '',
  surname: '',
  email: '',
  external_user: INITIAL_EXTERNAL_USER_VALUES,
}

const UserSchema = Yup.object().shape({
  name: Yup.string().required().label('Nome'),
  surname: Yup.string().required().label('Cognome'),
  email: Yup.string().email().required().label('Email'),
})

const AdminSchema = UserSchema.shape({
  administrator: Yup.object().shape({
    has_files_management: Yup.bool().required(),
    has_folders_management: Yup.bool().required(),
    has_policies_management: Yup.bool().required(),
    has_users_management: Yup.bool().required(),
    files_management_policies: Yup.mixed(),
  }),
})

const ExternalUserSchema = UserSchema.shape({
  external_user: Yup.object().shape({
    company: Yup.string().required().label('Azienda'),
    group: Yup.string().required().label('Gruppo'),
    policies: Yup.mixed(),
  }),
})

export default function UserForm({ userData, save, mode, resendMail }) {
  const initialValues = userData ?? INITIAL_VALUES
  const [deSchema, setDeSchema] = useState(() => {
    if (initialValues.external_user) {
      return ExternalUserSchema
    } else if (initialValues.administrator) {
      return AdminSchema
    } else {
      return UserSchema
    }
  })
  const [{ data: groups }] = useGroupsList()
  const [{ data: policies }] = usePoliciesList()
  const [{ data: users }] = useUsersList()

  const [modalMail, actionsModalMail] = useModalTrigger()

  const companies = useMemo(() => {
    if (!users) {
      return []
    }
    return uniqBy(
      users.filter((u) => u.external_user),
      'external_user.company'
    ).map((c) => ({
      value: c.external_user.company,
      label: c.external_user.company,
    }))
  }, [users])

  const navigate = useNavigate()

  return (
    <Formik
      onSubmit={(values, actions) => {
        return save(values)
          .catch((error) => actions.setErrors(transformErrorForForm(error)))
          .then(() => {
            navigate('/users', {
              state: {
                message:
                  mode === 'create'
                    ? 'Utente aggiunto con successo'
                    : 'Utente aggiornato con successo',
              },
            })
          })
      }}
      validationSchema={deSchema}
      initialValues={initialValues}
    >
      {({ handleSubmit, values, setValues }) => (
        <form onSubmit={handleSubmit}>
          <div className="mx-4 mt-4">
            <h5 className="title-user">Informazioni Utente</h5>
            <div className="col-md-8">
              <div className="mb-4">
                <Field
                  label="Nome"
                  component={InputField}
                  name="name"
                  placeholder="Nome"
                />
              </div>
              <div className="mb-4">
                <Field
                  label="Cognome"
                  component={InputField}
                  name="surname"
                  placeholder="Cognome"
                />
              </div>
              <div className="mb-4">
                <Field
                  label="Email"
                  component={InputField}
                  name="email"
                  type="email"
                  placeholder="nome.cognome@mail.com"
                />
              </div>
              <div className="mb-4 row">
                <Label className="col-md-2" style={{ marginTop: 5 }}>
                  Tipo Cliente{' '}
                </Label>
                <div className="col-md-10">
                  <Input
                    name="user_type"
                    type="select"
                    onChange={(e) => {
                      const value = e.target.value
                      if (value === '') {
                        setDeSchema(UserSchema)
                        setValues((deValues) => ({
                          ...deValues,
                          administrator: undefined,
                          external_user: undefined,
                        }))
                      } else if (value === 'external_user') {
                        setDeSchema(ExternalUserSchema)
                        setValues((deValues) => ({
                          ...deValues,
                          administrator: undefined,
                          external_user: INITIAL_EXTERNAL_USER_VALUES,
                        }))
                      } else if (value === 'administrator') {
                        setDeSchema(AdminSchema)
                        setValues((deValues) => ({
                          ...deValues,
                          administrator: INITIAL_ADMIN_VALUES,
                          external_user: undefined,
                        }))
                      }
                    }}
                    value={
                      values.external_user
                        ? 'external_user'
                        : values.administrator
                        ? 'administrator'
                        : ''
                    }
                  >
                    <option value={'external_user'}>Cliente</option>
                    <option value={'administrator'}>Amministratore</option>
                  </Input>
                </div>
              </div>
              {values.external_user && (
                <div>
                  <h5 className="title-user">Informazioni cliente</h5>
                  <div className="mb-4">
                    {/* <Field
                      label="Azienda"
                      component={InputField}
                      name="external_user.company"
                    /> */}
                    <SelectField
                      name="external_user.company"
                      label="Azienda"
                      formatCreateLabel={(userInput) =>
                        `Nuova azienda: ${userInput}`
                      }
                      placeholder={"Seleziona o crea un'azienda"}
                    >
                      {companies.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </SelectField>
                  </div>
                  <div className="mb-4">
                    <Field
                      label="Gruppo"
                      type="select"
                      component={InputField}
                      name="external_user.group"
                    >
                      <option value={''}>-- Seleziona Gruppo --</option>
                      {groups &&
                        groups.map((group) => (
                          <option key={group.id} value={group.id}>
                            {group.name}
                          </option>
                        ))}
                    </Field>
                  </div>
                  <div className="mb-4">
                    <Field
                      label="Policy"
                      isLoading={policies === null}
                      getOptionLabel={(p) => p.name}
                      options={policies ?? []}
                      component={MultiSelectField}
                      placeholder={'Seleziona una o più policies'}
                      name="external_user.policies"
                    />
                  </div>
                </div>
              )}
              {values.administrator && (
                <div>
                  <h5 className="title-user">Permessi Amministrativi</h5>
                  <Field
                    label="Possibilità di gestire i files"
                    component={SwtichField}
                    name="administrator.has_files_management"
                  />
                  <Field
                    label="Possibilità di gestire le cartelle"
                    component={SwtichField}
                    name="administrator.has_folders_management"
                  />
                  <Field
                    label="Possibilità di gestire le utenze (e le relative policy di sistema)"
                    component={SwtichField}
                    name="administrator.has_users_management"
                  />
                  <Field
                    label="Possibilità di gestire le policy di visibilità"
                    component={SwtichField}
                    name="administrator.has_policies_management"
                  />
                  <Field
                    label="Policy"
                    isLoading={policies === null}
                    getOptionLabel={(p) => p.name}
                    options={policies ?? []}
                    component={MultiSelectField}
                    name="administrator.files_management_policies"
                  />
                </div>
              )}
            </div>
            <div className="d-flex mt-5 mb-4 flex-row justify-content-between align-items-center">
              <Button
                onClick={() => navigate('/users')}
                outline
                color="igp-blue"
              >
                Annulla
              </Button>
              <Button color="igp-blue" type="submit">
                {userData ? 'Modifica' : 'Aggiungi'}
              </Button>
            </div>
            {!userData?.last_login && userData?.last_invitation && (
              <Alert
                color="danger"
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  L'utente non ha mai effettuato l'accesso, l'e-mail di
                  iscrizione è stata inviata in data:{' '}
                  <strong className="mx-1">
                    {userData &&
                      dayjs(userData.last_invitation).format('DD/MM/YYYY HH:mm')}
                  </strong>
                </div>
                <div>
                  <Button
                    onClick={() => actionsModalMail.open()}
                    color="igp-blue"
                    outline
                  >
                    Reinvia E-mail
                  </Button>
                </div>
              </Alert>
            )}
            {userData && (
              <Modal
                isOpen={modalMail.isOpen}
                toggle={() => actionsModalMail.toggle()}
                centered
              >
                <ModalHeader toggle={() => actionsModalMail.toggle()}>
                  Invio Mail Iscrizione - {userData.name} {userData.surname}
                </ModalHeader>
                <ModalBody>
                  Sei sicuro di voler reinviare l'email di iscrizione
                  all'utente: <br />
                  <strong>
                    {userData.name} {userData.surname}
                  </strong>
                  ?
                </ModalBody>
                <ModalFooter className="d-flex justify-content-between">
                  <Button
                    color="igp-blue"
                    outline
                    onClick={() => actionsModalMail.toggle()}
                  >
                    Annulla
                  </Button>
                  <Button
                    color="igp-blue"
                    onClick={() => {
                      return resendMail
                        .onSuccess(() => actionsModalMail.toggle())
                        .asPromise(userData.id)
                    }}
                  >
                    Invia
                  </Button>
                </ModalFooter>
              </Modal>
            )}
          </div>
        </form>
      )}
    </Formik>
  )
}
