import { rj, useRj } from 'react-rocketjump'
import api from '../api'

const UpdateProfileRj = rj({
  effectCaller: 'configured',
  effect: (token) => (data) => {
    return api.auth(token).patch('/api/me/', data)
  },
})

export function useUpdateProfile() {
  return useRj(UpdateProfileRj)
}

const UpdatePasswordRj = rj({
  effectCaller: 'configured',
  effect: (token) => (data) => {
    return api.auth(token).put('/api/change-password/', data)
  },
})

export function useUpdatePassword() {
  return useRj(UpdatePasswordRj)
}
