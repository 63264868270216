import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const ModalDelete = ({ isOpen, toggle, deleting, onDelete, body }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md" centered data-test="modal-delete">
      <ModalHeader toggle={toggle}>Elimina</ModalHeader>
      <ModalBody>
        <div className="container">{body}</div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Button color="danger" outline onClick={toggle}>
          Chiudi
        </Button>
        <Button color="danger" onClick={onDelete} disabled={deleting}>
          Elimina
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalDelete
