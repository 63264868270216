import { NavLink } from 'react-router-dom'
import { Nav, NavItem } from 'reactstrap'
import { useAuthUser } from 'use-eazy-auth'

export default function Sidebar() {
  const { user } = useAuthUser()
  return (
    <div className="sidebar col-md-3 col-lg-2 d-md-block position-fixed-md h-100-md sidebar order-md-1 order-2">
      <Nav vertical>
        <NavItem>
          <NavLink to="/folders" className="text-white" data-test="manage-files">
            Gestione File
          </NavLink>
        </NavItem>
        {user?.administrator?.has_users_management && (
          <NavItem>
            <NavLink to="/groups" className="text-white" data-test="manage-groups">
              Gestione Gruppi
            </NavLink>
          </NavItem>
        )}
        {user?.administrator?.has_policies_management && (
          <NavItem>
            <NavLink to="/policies" className="text-white" data-test="manage-policies">
              Gestione Policy
            </NavLink>
          </NavItem>
        )}
        {user?.administrator?.has_users_management && (
          <NavItem>
            <NavLink to="/users" className="text-white" data-test="manage-users">
              Gestione Utenti
            </NavLink>
          </NavItem>
        )}
      </Nav>
    </div>
  )
}
