import { Outlet } from 'react-router'
import Sidebar from './Sidebar'
import logo from '../assets/logo.png'
import TopBar from './TopBar'
import { useAuthActions } from 'use-eazy-auth'

export default function LayoutSidebar() {
  const isMobile = window.matchMedia('(max-width: 768px)').matches
  const { logout } = useAuthActions()

  if (isMobile) {
    return (
      <div
        className="position-fixed d-flex flex-column align-items-center justify-content-center"
        style={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          fontSize: 19,
          textAlign: 'center',
        }}
      >
        <div
          className="btn btn-link text-decoration-none position-absolute"
          onClick={logout}
          style={{ right: 20, top: 20, fontSize: 20}}
        >
          Logout
        </div>
        <img src={logo} className="logo" alt="logo igp"></img>
        <div className="mt-5">
          Si prega di utilizzare <br />
          la sezione Admin da Desktop.
        </div>
      </div>
    )
  }

  return (
    <div className="d-flex h-100">
      <TopBar />
      <div className="container-fluid h-100">
        <div className="row h-100">
          <Sidebar />
          <main className="col-md-9 ms-sm-auto col-lg-10 order-1 order-md-2 d-flex flex-column justify-content-start align-items-stretch px-0">
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  )
}
