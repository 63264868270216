import UserForm from '../../components/UserForm'
import { Link, useParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { useUserDetail } from '../../hooks/users'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { ReactComponent as IconTrash } from '../../assets/icons/icon-trash-white.svg'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap'

export default function EditUser() {
  const { id } = useParams()
  const [{ data: userData }, { updateUser, deleteUser, resendMail }] = useUserDetail(id)

  const navigate = useNavigate()

  const [modalDelete, actionsModalDelete] = useModalTrigger()

  if (!userData) {
    return (
      <div className="text-center">
        <Spinner />
      </div>
    )
  }

  return (
    <div>
      <div className="page-header mx-4">
        <h3>
          {userData.name} {userData.surname}
        </h3>
      </div>
      <div className="page-toolbar d-flex justify-content-between align-items-center pt-3 pb-3 mx-4">
        <div className="page-breadcrumb">
          <Link className="text-decoration-none" to="/users">
            Gestione Utenti
          </Link>{' '}
          / Modifica Utente
        </div>
        <Button
          color="danger"
          size="sm"
          className="px-3"
          onClick={() => {
            actionsModalDelete.open(userData)
          }}
          data-test="delete"
        >
          <IconTrash />
          {' Elimina'}
        </Button>
      </div>
      <UserForm
        userData={userData}
        resendMail={resendMail}
        save={(data) => updateUser.asPromise(id, data)}
      />
      {modalDelete.value && (
        <Modal
          isOpen={modalDelete.isOpen}
          toggle={actionsModalDelete.toggle}
          size="md"
          centered
          data-test="modal-delete"
        >
          <ModalHeader toggle={actionsModalDelete.toggle}>Elimina</ModalHeader>
          <ModalBody>
            Sei sicuro di eliminare l'utente {userData.name} {userData.surname}?
          </ModalBody>
          <ModalFooter className="d-flex justify-content-between">
            <Button
              outline
              color="danger"
              size="sm"
              className="me-2"
              onClick={(e) => {
                actionsModalDelete.close()
              }}
            >
              Annulla
            </Button>
            <Button
              color="danger"
              size="sm"
              className="px-3"
              onClick={() => {
                deleteUser
                  .onSuccess(() => {
                    actionsModalDelete.close()
                    navigate('/users')
                  })
                  .asPromise(userData.id)
              }}
            >
              <IconTrash />
              {' Elimina'}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  )
}
