import { Fragment, useMemo } from 'react'
import { Link } from 'react-router-dom'

export default function Breadcrumb({ path, isUser = false }) {
  const link = useMemo(() => {
    if (!isUser) {
      return '/folders'
    } else {
      return '/file-browser'
    }
  }, [isUser])

  return (
    <div>
      <Link
        to={link}
        className="text-decoration-none"
      >
        Home
      </Link>{' '}
      {path &&
        path.length > 0 &&
        path.map((folder, i) =>
          path.length - 1 === i ? (
            <span key={folder.id}>
              <span className="breadcrumb-separator">/</span>
              {folder.name}
            </span>
          ) : (
            <Fragment key={folder.id}>
              <span className="breadcrumb-separator">/</span>
              <Link
                to={`${link}/${folder.id}`}
                className="text-decoration-none"
              >
                {folder.name}
              </Link>
            </Fragment>
          )
        )}
    </div>
  )
}
