import { Route, Routes } from 'react-router'
import PageNotFound from '../../components/PageNotFound'
import NewUser from './NewUser'
import EditUser from './EditUser'
// import GroupDetail from './GroupDetail'
import UsersList from './UsersList'

export default function Users() {
  return (
    <Routes>
      <Route index element={<UsersList />} />
      <Route path="new" element={<NewUser />} />
      <Route path=":id" element={<EditUser />} />
      {/* <Route path=":id" element={<GroupDetail />} /> */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}
