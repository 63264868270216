import { Field, Formik } from 'formik'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useState } from 'react'
import { Button, Form } from 'reactstrap'
import { useAuthActions } from 'use-eazy-auth'
import { ReactComponent as IconEdit } from '../../assets/icons/icon-edit.svg'
import { AutoAlert } from '../../components/AutoAlert'
import { InputField } from '../../components/Fields'
import { transformErrorForForm } from '../../components/Fields/utils'
import { useUpdatePassword, useUpdateProfile } from '../../hooks/profile'
import { ChangePasswordModal } from './ChangePasswordModal'

export function ProfileAdmin({ user }) {
  const [editable, setEditable] = useState(false)
  const { updateUser } = useAuthActions()
  const [, { run: updateProfile }] = useUpdateProfile()
  const [, { run: updatePassword }] = useUpdatePassword()

  const [passwordModalState, passwordModalActions] = useModalTrigger()

  return (
    <div className="flex-1 d-flex flex-column justify-content-start align-items-stretch" data-test="profile-admin">
      <div className="page-header mx-4 d-flex flex-row justify-content-between align-items-center">
        <h3>Profilo Utente</h3>
        {!editable && (
          <Button
            outline
            color="igp-blue"
            size="sm"
            className="px-4 d-inline-flex flex-row align-items-center"
            onClick={() => {
              setEditable((v) => !v)
            }}
            data-test="edit"
          >
            <IconEdit className="me-1" />
            {' Modifica'}
          </Button>
        )}
      </div>
      <AutoAlert>
        <div style={{ height: 60 }} />
      </AutoAlert>
      <div className="pt-4 px-4 flex-1 d-flex flex-column justify-content-start">
        <Formik
          initialValues={{
            name: user.name,
            surname: user.surname,
            email: user.email,
          }}
          enableReinitialize
          onSubmit={(values, actions) => {
            return updateProfile
              .asPromise(values)
              .catch((error) => actions.setErrors(transformErrorForForm(error)))
              .then((result) => {
                setEditable(false)
                updateUser(result)
              })
          }}
        >
          {(formik) => {
            return (
              <Form
                onSubmit={formik.handleSubmit}
                className="flex-1 d-flex flex-column justify-content-start align-items-stretch"
              >
                <div className="col-12 col-lg-6">
                  <Field
                    name="name"
                    label="Nome"
                    component={InputField}
                    plaintext={!editable}
                    disabled={!editable}
                  />
                  <Field
                    name="surname"
                    label="Cognome"
                    component={InputField}
                    plaintext={!editable}
                    disabled={!editable}
                  />
                  <Field
                    name="email"
                    label="Email"
                    component={InputField}
                    plaintext={!editable}
                    disabled={!editable}
                    type="email"
                  />
                </div>
                {!editable && (
                  <div style={{ paddingTop: 60 }}>
                    <Button
                      color="igp-blue"
                      size="sm"
                      className="px-4 d-inline-flex flex-row align-items-center"
                      onClick={() => {
                        passwordModalActions.open('dummy')
                      }}
                      data-test="change-password"
                    >
                      <IconEdit className="me-1" />
                      {' Modifica password'}
                    </Button>
                  </div>
                )}
                <div className="flex-1" />
                {editable && (
                  <div className="pb-4 d-flex flex-row justify-content-between align-items-stretch">
                    <Button
                      outline
                      color="igp-blue"
                      onClick={() => {
                        setEditable(false)
                        formik.resetForm()
                      }}
                    >
                      Annulla
                    </Button>
                    <Button color="igp-blue" type="submit">
                      Salva
                    </Button>
                  </div>
                )}
              </Form>
            )
          }}
        </Formik>
      </div>
      {passwordModalState.value && (
        <ChangePasswordModal
          isOpen={passwordModalState.isOpen}
          onClosed={passwordModalActions.onClosed}
          onCancel={passwordModalActions.close}
          onSubmit={(values) => {
            return updatePassword
              .onSuccess(() => {
                passwordModalActions.close()
              })
              .asPromise(values)
          }}
        />
      )}
    </div>
  )
}
