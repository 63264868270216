import { deps, rj, useRj, useRunRj } from 'react-rocketjump'
import api from '../api'

const UsersListState = rj({
  name: 'UsersList',
  effectCaller: 'configured',
  effect:
    (t) =>
    (params = {}) => {
      return api.auth(t).get('/api/users', params)
    },
  mutations: {
    bulkDelete: rj.mutation.single({
      effect: (token) => (ids) => {
        return api
          .auth(token)
          .mapResponse(() => ids)
          .post('/api/users/bulk_delete/', { ids })
      },
      updater: (state, result) => {
        return {
          ...state,
          data: state.data.filter((datum) => !result.includes(datum.id)),
        }
      },
    }),
  },
})

const NO_PARAMS = {}
export function useUsersList(params = NO_PARAMS) {
  return useRunRj(UsersListState, [deps.maybe(params)], false)
}

const UserCreateState = rj().effect({
  name: 'UsersCreate',
  effectCaller: 'configured',
  effect: (t) => (data) => api.auth(t).post('/api/users', data),
})

export function useCreateUser() {
  return useRj(UserCreateState)
}

const UserDetailState = rj()
  .mutations({
    updateUser: {
      effect: (t) => (id, data) => api.auth(t).put(`/api/users/${id}`, data),
      updater: 'updateData',
    },
    deleteUser: rj.mutation.single({
      effect: (token) => (id) => {
        return api.auth(token).delete(`/api/users/${id}`)
      },
      updater: 'updateData',
    }),
    resendMail: rj.mutation.single({
      effect: (token) => (id) => {
        return api
          .auth(token)
          .post('/api/users/resend_mail/', { id })
      },
      updater: 'updateData',
    }),
  })
  .effect({
    name: 'UserDetail',
    effectCaller: 'configured',
    effect: (t) => (id) => api.auth(t).get(`/api/users/${id}`),
  })

export function useUserDetail(id) {
  return useRunRj(UserDetailState, [id])
}

const downloadReportRj = rj({
  effectCaller: 'configured',
  effect: (token) => () => {
    return api
      .auth(token)
      .request({
        responseType: 'arraybuffer',
      })
      .get(`/api/users/export/`)
  },
})

export function useDownloadReport() {
  return useRj(downloadReportRj)
}
