import { omit } from 'lodash'
import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { Link, useSearchParams } from 'react-router-dom'
import {
  Button,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Table,
} from 'reactstrap'
import { ReactComponent as IconAdd } from '../../assets/icons/icon-add.svg'
import { ReactComponent as IconTrash } from '../../assets/icons/icon-trash-white.svg'
import { AutoAlert } from '../../components/AutoAlert'
import { useDownloadReport, useUsersList } from '../../hooks/users'
import { ReactComponent as CheckIcon } from '../../assets/icons/icon-check.svg'
import { ReactComponent as ExportIcon } from '../../assets/icons/icon-export.svg'
import classNames from 'classnames'

const ADMIN_PERMISSION_KEYS = [
  'has_files_management',
  'has_folders_management',
  'has_users_management',
  'has_policies_management',
]

export default function UsersList() {
  const [searchInput, setSearchInput] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()
  const filters = useMemoCompare({
    search: searchParams.get('search') ?? '',
  })
  const [{ data: users }, { bulkDelete }] = useUsersList(filters)
  const [selected, setSelected] = useState({})
  const atLeastOneSelected = Object.keys(selected).length > 0

  const navigate = useNavigate()
  const [modalDelete, actionsModalDelete] = useModalTrigger()
  const [, { run: downloadReport }] = useDownloadReport()

  if (!users) {
    return (
      <div className="d-flex justify-content-center w-100 align-items-center text-center spinner-container">
        <Spinner />
      </div>
    )
  }

  // console.log(users)

  return (
    <div data-test="page-users-list">
      <AutoAlert>
        <div className="page-header mx-4 d-flex flex-row justify-content-between align-items-center pointer">
          <h3>Gestione Utenti</h3>
          <span
            className="text-primary fw-500 d-inline-flex flex-row align-items-center"
            onClick={() => {
              downloadReport
                .onSuccess((buffer) => {
                  const blob = new Blob([buffer], { type: 'text/csv' })
                  const url = URL.createObjectURL(blob)
                  const a = document.createElement('a')
                  a.setAttribute('download', 'utenti.xlsx')
                  a.setAttribute('href', url)
                  a.setAttribute('target', '_blank')
                  a.setAttribute('rel', 'noopener noreferrer')
                  a.style.display = 'none'
                  document.body.appendChild(a)

                  a.click()

                  setTimeout(() => {
                    document.body.removeChild(a)
                  }, 50)
                })
                .run()
            }}
          >
            <ExportIcon className="me-1" />
            Esporta
          </span>
        </div>
      </AutoAlert>
      {!atLeastOneSelected && (
        <div className="page-toolbar d-flex flex-row justify-content-between pt-3 pb-3 mx-4">
          <Form
            className="d-flex flex-row justify-content-start align-items-center"
            onSubmit={(e) => {
              e.preventDefault()
              setSearchParams({ ...filters, search: searchInput })
              return false
            }}
          >
            <Input
              bsSize="sm"
              placeholder="Cerca"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            ></Input>
            <Button
              outline
              color="igp-blue"
              size="sm"
              className="ms-2"
              onClick={(e) =>
                setSearchParams({ ...filters, search: searchInput })
              }
            >
              Cerca
            </Button>
          </Form>
          <Button
            onClick={() => navigate('/users/new')}
            color="igp-blue"
            size="sm"
            className="px-3"
            data-test="add"
          >
            <IconAdd />
            {' Aggiungi'}
          </Button>
        </div>
      )}
      {atLeastOneSelected && (
        <div className="page-toolbar d-flex flex-row justify-content-end pt-3 pb-3 mx-4">
          <Button
            outline
            color="danger"
            size="sm"
            className="me-2"
            onClick={(e) => setSelected({})}
          >
            Annulla
          </Button>
          <Button
            color="danger"
            size="sm"
            className="px-3"
            onClick={() => {
              actionsModalDelete.open('dummy')
            }}
            data-test="delete"
          >
            <IconTrash />
            {' Elimina'}
          </Button>
        </div>
      )}
      <Table className="mt-3">
        <thead>
          <tr>
            <th></th>
            <th>Nome</th>
            <th>Email</th>
            <th>Azienda</th>
            <th>Gruppo</th>
            <th>Permessi amm.</th>
            <th className="text-center">Invio email</th>
          </tr>
        </thead>
        <tbody>
          {users &&
            users.map((userData, i) => {
              return (
                <tr
                  key={userData.id}
                  className={classNames({
                    'bg-light': i % 2 !== 1 && !selected[userData.id],
                    'bg-igp-focus': selected[userData.id],
                  })}
                >
                  <td className="text-end">
                    <Input
                      type="checkbox"
                      checked={selected[userData.id] ?? false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelected((prev) => ({
                            ...prev,
                            [userData.id]: true,
                          }))
                        } else {
                          setSelected((prev) => omit(prev, userData.id))
                        }
                      }}
                      data-test="checkbox"
                    />
                  </td>
                  <td>
                    <Link to={`/users/${userData.id}`}>
                      {userData.name} {userData.surname}
                    </Link>
                  </td>
                  <td style={{ opacity: userData.last_login ? 1 : 0.5 }}>
                    {userData.email}
                  </td>
                  <td>{userData?.external_user?.company ?? null}</td>
                  <td>{userData?.external_user?.group_data?.name ?? null}</td>
                  <td>
                    {ADMIN_PERMISSION_KEYS.filter(
                      (key) => userData.administrator?.[key]
                    ).length || '-'}
                  </td>
                  <td className="text-center">
                    {userData?.external_user?.email_consent && <CheckIcon />}
                  </td>
                </tr>
              )
            })}
        </tbody>
      </Table>
      {modalDelete.value && (
        <Modal
          isOpen={modalDelete.isOpen}
          toggle={actionsModalDelete.toggle}
          size="md"
          centered
          data-test="modal-delete"
        >
          <ModalHeader>Elimina</ModalHeader>
          <ModalBody>Sei sicuro di eliminare gli utenti selezionati?</ModalBody>
          <ModalFooter className="d-flex justify-content-between">
            <Button
              outline
              color="danger"
              size="sm"
              className="me-2"
              onClick={(e) => {
                setSelected({})
                actionsModalDelete.close()
              }}
            >
              Annulla
            </Button>
            <Button
              color="danger"
              size="sm"
              className="px-3"
              onClick={() => {
                bulkDelete(
                  Array.from(Object.keys(selected)).map((n) => parseInt(n, 10))
                )
                setSelected({})
                actionsModalDelete.close()
              }}
            >
              <IconTrash />
              {' Elimina'}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  )
}
