import classNames from 'classnames'
import * as dayjs from 'dayjs'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Link } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router'
import { Button, Spinner } from 'reactstrap'
import { useAuthUser } from 'use-eazy-auth'
import { ReactComponent as IconAddFolder } from '../../assets/icons/icon-add-folder.svg'
import { ReactComponent as ExportIcon } from '../../assets/icons/icon-export.svg'
import { ReactComponent as IconPencil } from '../../assets/icons/icon-pencil.svg'
import { ReactComponent as IconPencilPrimary } from '../../assets/icons/icon-pencil-primary.svg'
import { ReactComponent as ReportIcon } from '../../assets/icons/icon-report.svg'
import { ReactComponent as IconTrash } from '../../assets/icons/icon-trash-blue.svg'
import ModalDelete from '../../components/ModalDelete'
import ModalFolder from '../../components/ModalFolder'
import ModalPolicies from '../../components/ModalPolicies'
import ModalUsers from '../../components/ModalUsers'
import {
  useDownloadReport,
  useDownloadStats,
  useFoldersList,
} from '../../hooks/folders'
import { usePoliciesList } from '../../hooks/policies'
import { useUsersList } from '../../hooks/users'
import { AutoAlert } from '../../components/AutoAlert'

export default function FoldersList() {
  const { user } = useAuthUser()
  const [{ data: folders }, { patchFolder, addFolder, deleteFolder }] =
    useFoldersList()
  const [{ data: policies }] = usePoliciesList()
  const [{ data: users }] = useUsersList()
  const [, { run: downloadReport }] = useDownloadReport()
  const [, { run: downloadStats }] = useDownloadStats()

  const [modalSetPolicies, actionsModalSetPolicies] = useModalTrigger()
  const [modalSetUsers, actionsModalSetUsers] = useModalTrigger()
  const [modalDelete, actionsDelete] = useModalTrigger()
  const [modalAddFolder, actionModalAddFolder] = useModalTrigger()
  const [modalUpdateFolder, actionsModalUpdateFolder] = useModalTrigger()

  const location = useLocation()
  const navigate = useNavigate()

  if (!folders) {
    return (
      <div className="d-flex justify-content-center w-100 align-items-center text-center spinner-container">
        <Spinner />
      </div>
    )
  }

  return (
    <div data-test="page-folders-list">
      <AutoAlert>
        <div className="page-header mx-4 d-flex flex-row justify-content-between align-items-center">
          <h3>Gestione file</h3>
          <div className="d-flex flex-row justify-content-end align-items-center">
            <span
              className="text-primary fw-500 d-inline-flex flex-row align-items-center me-5 pointer"
              onClick={() => {
                downloadReport
                  .onSuccess((buffer) => {
                    const blob = new Blob([buffer], { type: 'text/csv' })
                    const url = URL.createObjectURL(blob)
                    const a = document.createElement('a')
                    a.setAttribute('download', 'export_files.xlsx')
                    a.setAttribute('href', url)
                    a.setAttribute('target', '_blank')
                    a.setAttribute('rel', 'noopener noreferrer')
                    a.style.display = 'none'
                    document.body.appendChild(a)

                    a.click()

                    setTimeout(() => {
                      document.body.removeChild(a)
                    }, 50)
                  })
                  .run()
              }}
            >
              <ReportIcon className="me-1" />
              Report
            </span>
            <span
              className="text-primary fw-500 d-inline-flex flex-row align-items-center pointer"
              onClick={() => {
                downloadStats
                  .onSuccess((buffer) => {
                    const blob = new Blob([buffer], { type: 'text/csv' })
                    const url = URL.createObjectURL(blob)
                    const a = document.createElement('a')
                    a.setAttribute('download', 'export_stats.xlsx')
                    a.setAttribute('href', url)
                    a.setAttribute('target', '_blank')
                    a.setAttribute('rel', 'noopener noreferrer')
                    a.style.display = 'none'
                    document.body.appendChild(a)

                    a.click()

                    setTimeout(() => {
                      document.body.removeChild(a)
                    }, 50)
                  })
                  .run()
              }}
            >
              <ExportIcon className="me-1" />
              Statistiche
            </span>
          </div>
        </div>
      </AutoAlert>
      <div className="page-toolbar d-flex justify-content-between align-items-center pt-3 pb-3 mx-4">
        <div className="page-breadcrumb">Home</div>
        <Button
          onClick={() => actionModalAddFolder.open()}
          color="igp-blue"
          size="sm"
          className="px-3 mx-3"
        >
          <IconAddFolder />
          {'  Cartella'}
        </Button>
      </div>
      <div className="pt-3">
        {folders?.content_folders?.map((content, i) => (
          <div
            key={content.id}
            className={classNames(
              'pt-3 pb-3 px-4 d-flex position-relative align-items-center justify-content-between',
              {
                'bg-light': i % 2 !== 1,
              }
            )}
          >
            <div className="d-flex" style={{ width: '50%', marginRight: 50 }}>
              <div>
                <span
                  className={`fiv-viv fiv-icon-folder fiv-size-xs icon-folder`}
                />
              </div>
              <div className="mx-2">
                <Link
                  to={`/folders/${content.id}`}
                  className="mb-0 text-primary fw-500 text-decoration-none"
                >
                  {content.name}
                </Link>

                <div>
                  <small>{content.description}</small>
                  <div className="info-folders">
                    <div>{content.content_count} files</div>
                    <div className="mx-5">
                      {dayjs(content.created_at).format('DD/MM/YYYY')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between pl-5">
              <div>
                <Button
                  disabled={!user?.administrator?.has_folders_management}
                  color="igp-blue"
                  className="button-pill"
                  size="sm"
                  outline
                  style={{
                    visibility: content.is_writable ? undefined : 'hidden',
                  }}
                  onClick={() => actionsModalSetUsers.open(content)}
                >
                  <IconPencil />
                  <span className="mx-2">Utenti {content.users.length}</span>
                </Button>
              </div>
              <div className="mx-3">
                <Button
                  disabled={!user?.administrator?.has_folders_management}
                  color="igp-blue"
                  className="button-pill"
                  size="sm"
                  style={{
                    visibility: content.is_writable ? undefined : 'hidden',
                  }}
                  outline
                  onClick={() => actionsModalSetPolicies.open(content)}
                >
                  <IconPencil />
                  <span className="mx-2">Policy {content.policies.length}</span>
                </Button>
              </div>
            </div>
            <div
              className="d-flex align-items-center"
            >
              {user?.administrator?.has_folders_management &&
                content.is_writable && (
                  <div className="mx-4">
                    <IconPencilPrimary
                      width={18}
                      height={18}
                      onClick={() => actionsModalUpdateFolder.open(content)}
                    />
                  </div>
                )}
              {user?.administrator?.has_folders_management &&
                content.is_writable && (
                  <div>
                    <IconTrash
                      width={20}
                      height={20}
                      onClick={() => actionsDelete.open(content)}
                    />
                  </div>
                )}
            </div>
          </div>
        ))}
      </div>
      <ModalPolicies
        isOpen={modalSetPolicies.isOpen}
        toggle={actionsModalSetPolicies.toggle}
        policies={policies ? policies : []}
        currentPolicies={modalSetPolicies.value}
        onExited={actionsModalSetPolicies.onClosed}
        save={(data) => {
          return patchFolder
            .onSuccess(actionsModalSetPolicies.close)
            .asPromise(modalSetPolicies.value.id, { policies: data.policies })
        }}
      />
      <ModalUsers
        isOpen={modalSetUsers.isOpen}
        toggle={actionsModalSetUsers.toggle}
        users={users ? users.filter((user) => !user.administrator) : []}
        currentUsers={modalSetUsers.value}
        onExited={actionsModalSetUsers.onClosed}
        save={(data) => {
          return patchFolder
            .onSuccess(actionsModalSetUsers.close)
            .asPromise(modalSetUsers.value.id, { users: data.users })
        }}
      />
      <ModalDelete
        isOpen={modalDelete.isOpen}
        toggle={actionsDelete.toggle}
        onExited={actionsDelete.onClosed}
        body={
          modalDelete.value
            ? `Sei sicuro di voler eliminare ${modalDelete.value.name}?`
            : ''
        }
        onDelete={() => {
          return deleteFolder
            .onSuccess(actionsDelete.close)
            .asPromise(modalDelete.value.id)
        }}
      />
      <ModalFolder
        isOpen={modalAddFolder.isOpen}
        toggle={actionModalAddFolder.toggle}
        onExited={actionModalAddFolder.onClosed}
        save={(folder) => {
          return addFolder
            .onSuccess((folder) => {
              actionModalAddFolder.close()
              navigate(location, {
                state: {
                  message: `Cartella ${folder.name} aggiunta con successo`,
                },
              })
            })
            .asPromise(folder)
        }}
      />
      <ModalFolder
        isOpen={modalUpdateFolder.isOpen}
        toggle={actionsModalUpdateFolder.toggle}
        // onExited={actionModalAddFolder.onClosed}
        folder={modalUpdateFolder.value}
        save={(folder) => {
          return patchFolder
            .onSuccess(() => {
              actionsModalUpdateFolder.close()
              navigate(location, {
                state: {
                  message: `Cartella ${folder.name} aggiornata con successo`,
                },
              })
            })
            .asPromise(folder.id, folder)
        }}
      />
    </div>
  )
}
