import { rj, useRj } from 'react-rocketjump'
import api from '../api'

const RecoverPasswordState = rj().effect({
  name: 'RecoverPassword',
  effect: (data) =>
    api.mapResponse(() => true).post(`/api/recover-password/`, data),
})

export function useRecoverPassword() {
  return useRj(RecoverPasswordState)
}
