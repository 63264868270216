import { omit } from 'lodash'
import { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Alert } from 'reactstrap'

export function AutoAlert({ children }) {
  const location = useLocation()
  const navigate = useNavigate()
  const timeoutRef = useRef()

  useEffect(() => {
    if (location.state?.message) {
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null
        navigate(location, {
          replace: true,
          state: omit(location.state, 'message', 'messageType'),
        })
      }, 2000)
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }
      }
    }
  }, [location, navigate])

  if (location.state?.message) {
    const messageType = location.state?.messageType ?? 'success'
    return (
      <Alert
        style={{ marginBottom: 0, height: 62 }}
        color={messageType}
        toggle={() => {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
            timeoutRef.current = null
          }
          navigate(location, {
            replace: true,
            state: omit(location.state, 'message', 'messageType'),
          })
        }}
      >
        {location.state.message}
      </Alert>
    )
  } else {
    return children
  }
}
