import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuthActions, useAuthState } from 'use-eazy-auth'

export default function Login() {
  const { loginLoading, loginError } = useAuthState()
  const { login, clearLoginError } = useAuthActions()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        if (email !== '' && password !== '') {
          login({ email, password })
        }
      }}
    >
      {loginError && (
        <div className="alert alert-danger alert-login">
          {loginError?.response?.detail ?? "Credenziali inserite errate. Impossibile effettuare il Login."}
        </div>
      )}
      <div className="form-login" data-test="login">
        <div>
          <h3 className='login'>Login</h3>
          <div>
            <label>Email</label>
            <input
              name="email"
              placeholder="Email"
              type="email"
              className="form-control"
              value={email}
              onChange={(e) => {
                clearLoginError()
                setEmail(e.target.value)
              }}
            />
          </div>
          <div className="mt-2">
            <label>Password</label>
            <input
              name="password"
              placeholder="Password"
              type="password"
              className="form-control"
              value={password}
              onChange={(e) => {
                clearLoginError()
                setPassword(e.target.value)
              }}
            />
          </div>
          <button
            className="btn btn-primary w-100 mt-4"
            disabled={loginLoading}
          >
            {!loginLoading ? 'Accedi' : 'Accedendo...'}
          </button>
          <Link className="text-decoration-none" to={'/recover'}>
            <div className="mt-3 text-end text-primary">Recupera Password</div>
          </Link>
        </div>
      </div>
    </form>
  )
}
