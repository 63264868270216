import { Route, Routes } from 'react-router'
import PageNotFound from '../../components/PageNotFound'
import FolderContentUser from './FolderContentUser'
import FolderListUser from './FolderListUser'

export default function FileBrowserUser() {
  return (
    <Routes>
      <Route index element={<FolderListUser />} />
      <Route path=":id" element={<FolderContentUser />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}
