import React, { useMemo } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Formik, Field } from 'formik'
import { setNullOnEmptyValues, transformErrorForForm } from './Fields/utils'
import { MultiSelectField } from './Fields'

const ModalUsers = ({ isOpen, toggle, save, users, currentUsers }) => {
  const initialValues = useMemo(() => {
    if (currentUsers && currentUsers.users.length > 0) {
      return {
        users: currentUsers.users,
      }
    }
    return {
      users: [],
    }
  }, [currentUsers])

  const usersToUse = useMemo(() => {
    if (!users || !currentUsers) {
      return []
    }
    return users.filter(
      (user) =>
        !currentUsers.inherited_users.filter(
          (userInherited) => userInherited.id === user.id
        ).length
    )
  }, [currentUsers, users])

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md" centered data-test="modal-users">
      <ModalHeader toggle={toggle}>Utenti</ModalHeader>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          return save(setNullOnEmptyValues(values)).then(
            () => {
              actions.setSubmitting(false)
            },
            (error) => {
              actions.setSubmitting(false)
              actions.setErrors(transformErrorForForm(error))
            }
          )
        }}
        render={({ handleSubmit, values, isSubmitting, errors }) => (
          <>
            <ModalBody>
              <form onSubmit={handleSubmit}>
                {currentUsers && currentUsers.inherited_users.length > 0 && (
                  <div className="mb-2">
                    <hr style={{ marginTop: 10, marginBottom: 5 }} />
                    <label className='mx-2 mb-1'>Utenti ereditati:</label>
                    <div>
                      {currentUsers.inherited_users.map((u) => (
                        <span
                          className="btn btn-sm btn-outline-primary btn-disable mx-2 mt-1"
                          key={u.id}
                        >
                          {u.name} {u.surname}
                        </span>
                      ))}
                    </div>
                    <hr style={{ marginTop: 10, marginBottom: 10 }} />
                  </div>
                )}
                <Field
                  label="Utenti"
                  isLoading={users === null}
                  getOptionLabel={(p) => p.name + ' ' + p.surname}
                  options={usersToUse ?? []}
                  noOptionsMessage={() => 'Non ci sono utenti selezionabili.'}
                  component={MultiSelectField}
                  placeholder={'Seleziona uno o più utenti'}
                  name="users"
                />
              </form>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
              <Button
                disabled={isSubmitting}
                color="igp-blue"
                outline
                onClick={toggle}
              >
                Chiudi
              </Button>
              <Button
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
                color="igp-blue"
              >
                Conferma
              </Button>
            </ModalFooter>
          </>
        )}
      />
    </Modal>
  )
}

export default ModalUsers
