import { useMemo } from 'react'
import { useAuthUser } from 'use-eazy-auth'
import { useLocation, useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { Button, Spinner } from 'reactstrap'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import * as dayjs from 'dayjs'
import { useFoldersList } from '../../hooks/folders'
import { ReactComponent as IconAddFile } from '../../assets/icons/icon-add-file.svg'
import { ReactComponent as IconAddFolder } from '../../assets/icons/icon-add-folder.svg'
import { ReactComponent as IconPencil } from '../../assets/icons/icon-pencil.svg'
import { ReactComponent as IconPencilPrimary } from '../../assets/icons/icon-pencil-primary.svg'
import { ReactComponent as IconTrash } from '../../assets/icons/icon-trash-blue.svg'
import { ReactComponent as IconDownload } from '../../assets/icons/icon-download.svg'
import classNames from 'classnames'
import ModalFolder from '../../components/ModalFolder'
import ModalFile from '../../components/ModalFile'
import ModalPolicies from '../../components/ModalPolicies'
import ModalUsers from '../../components/ModalUsers'
import IconFile from '../../components/IconFile'
import Breadcrumb from '../../components/Breadcrumb'
import { usePoliciesList } from '../../hooks/policies'
import { useUsersList } from '../../hooks/users'
import ModalDelete from '../../components/ModalDelete'
import { AutoAlert } from '../../components/AutoAlert'

export default function FolderContent() {
  const { user } = useAuthUser()
  const { id: folderId } = useParams()
  const [modalAddFolder, actionModalAddFolder] = useModalTrigger()
  const [modalAddFile, actionModalAddFile] = useModalTrigger()
  const [modalUpdateFolder, actionModalUpdateFolder] = useModalTrigger()
  const [modalUpdateFile, actionModalUpdateFile] = useModalTrigger()
  const [modalSetPolicies, actionsModalSetPolicies] = useModalTrigger()
  const [modalSetUsers, actionsModalSetUsers] = useModalTrigger()
  const [modalDelete, actionsDelete] = useModalTrigger()

  const [{ data: policies }] = usePoliciesList()
  const [{ data: users }] = useUsersList()

  const location = useLocation()
  const navigate = useNavigate()

  const filters = useMemo(() => {
    if (folderId) {
      return {
        folderId,
      }
    }
  }, [folderId])

  const [
    { data: folderContent },
    {
      addFolder,
      addFile,
      patchFolder,
      patchSharedFile,
      deleteFolder,
      deleteSharedFile,
    },
  ] = useFoldersList(filters)

  // const lengthPoliciesInherited = useMemo(() => {
  //   if (!folderContent) {
  //     return 0
  //   }
  //   let counter = 0
  //   for (const obj of folderContent.content_folders) {
  //     console.log(obj)
  //     counter += obj.inherited_policies.length
  //   }
  //   return counter
  // }, [folderContent])

  if (folderId && !folderContent) {
    return (
      <div className="d-flex justify-content-center w-100 align-items-center text-center spinner-container">
        <Spinner />
      </div>
    )
  }

  return (
    <div data-text="page-folder-content" data-test="page-folder-content">
      <AutoAlert>
        <div className="page-header mx-4">
          <h3>Gestione file</h3>
        </div>
      </AutoAlert>
      <div className="page-toolbar d-flex justify-content-between align-items-center pt-3 pb-3 mx-4">
        {folderContent.folder && (
          <div className="page-breadcrumb">
            <Breadcrumb path={folderContent.path} />
            <div className="d-flex mt-2">
              <Button
                disabled
                color="igp-blue"
                className="button-pill"
                size="sm"
                outline
              >
                <span>
                  Utenti{' '}
                  {folderContent.folder.users.length}
                </span>
              </Button>
              <Button
                disabled
                color="igp-blue"
                className="button-pill mx-2"
                size="sm"
                outline
              >
                <span>
                  Policy{' '}
                  {folderContent.folder.policies.length}
                </span>
              </Button>
            </div>
          </div>
        )}
        <div>
          {user?.administrator?.has_files_management && (
            <Button
              onClick={() => actionModalAddFile.open()}
              color="igp-blue"
              size="sm"
              className="px-3"
              data-test="upload-file"
            >
              <IconAddFile />
              {' File'}
            </Button>
          )}
          {user?.administrator?.has_folders_management && (
            <Button
              onClick={() => actionModalAddFolder.open()}
              color="igp-blue"
              size="sm"
              className="px-3 mx-3"
              data-test="create-folder"
            >
              <IconAddFolder />
              {'  Cartella'}
            </Button>
          )}
        </div>
      </div>
      <div className="pt-3">
        {folderContent?.content_folders?.map((content, i) => (
          <div
            key={content.id}
            className={classNames(
              'pt-3 pb-3 px-4 position-relative d-flex align-items-center justify-content-between',
              {
                'bg-light': i % 2 !== 1,
              }
            )}
          >
            <div className="d-flex" style={{ width: '50%', marginRight: 50 }}>
              <div>
                <span
                  className={`fiv-viv fiv-icon-folder fiv-size-xs mx-2 icon-folder`}
                />
              </div>
              <div className="mx-1">
                <Link
                  to={`/folders/${content.id}`}
                  className="text-primary"
                  style={{ textDecoration: 'none' }}
                >
                  {content.name}
                </Link>

                <div>
                  <div>
                    <small>{content.description}</small>
                    <div className="info-folders">
                      <div>{content.content_count} files</div>
                      <div className="mx-5">
                        {dayjs(content.created_at).format('DD/MM/YYYY')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between pl-5">
              <div>
                <Button
                  disabled={!user?.administrator?.has_folders_management}
                  color="igp-blue"
                  className="button-pill"
                  size="sm"
                  outline
                  onClick={() => actionsModalSetUsers.open(content)}
                  data-test="users"
                >
                  <IconPencil />
                  <span className="mx-2">Utenti {content.users.length + content.inherited_users.length}</span>
                </Button>
              </div>
              <div className="mx-3">
                <Button
                  disabled={!user?.administrator?.has_folders_management}
                  color="igp-blue"
                  className="button-pill"
                  size="sm"
                  outline
                  onClick={() => actionsModalSetPolicies.open(content)}
                  data-test="policy"
                >
                  <IconPencil />
                  <span className="mx-2">Policy {content.policies.length + content.inherited_policies.length}</span>
                </Button>
              </div>
            </div>
            <div className="d-flex align-items-center h-100">
              {user?.administrator?.has_folders_management && (
                <div className="mx-4">
                  <IconPencilPrimary
                    width={18}
                    height={18}
                    onClick={() => actionModalUpdateFolder.open(content)}
                  />
                </div>
              )}
              {user?.administrator?.has_folders_management && (
                <div>
                  <IconTrash
                    width={20}
                    height={20}
                    onClick={() =>
                      actionsDelete.open({ ...content, type: 'folder' })
                    }
                  />
                </div>
              )}
            </div>
          </div>
        ))}
        {folderContent?.content_files?.map((content, i) => (
          <div
            key={content.id}
            className={classNames(
              'pt-3 pb-3 px-4 position-relative d-flex align-items-center justify-content-between',
              {
                'bg-light': i % 2 !== 1,
              }
            )}
          >
            <div className="d-flex" style={{ width: '60%', marginRight: 100 }}>
              <div className="mb-0 fw-500" style={{ textDecoration: 'none' }}>
                <IconFile file={content.file} />
              </div>

              <div className="mx-1 w-100">
                {content.display_name}
                <br />
                <small>{content.description}</small>
                <div className="d-flex justify-content-between w-100">
                  <div className="info-folders">
                    <div>{content.size}</div>
                    <div className="mx-5">
                      {dayjs(content.created_at).format('DD/MM/YYYY')}
                    </div>
                  </div>
                  <div>
                    <a
                      download={content.name}
                      rel="noreferrer"
                      href={content.file}
                      target={'_blank'}
                    >
                      <IconDownload />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center h-100">
              {user?.administrator?.has_folders_management && (
                <div className="mx-4">
                  <IconPencilPrimary
                    width={18}
                    height={18}
                    onClick={() => actionModalUpdateFile.open(content)}
                    data-test="edit"
                  />
                </div>
              )}
              {user?.administrator?.has_files_management && (
                <div>
                  <IconTrash
                    width={20}
                    height={20}
                    onClick={() =>
                      actionsDelete.open({ ...content, type: 'file' })
                    }
                    data-test="delete"
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <ModalFolder
        isOpen={modalAddFolder.isOpen}
        toggle={actionModalAddFolder.toggle}
        // onExited={actionModalAddFolder.onClosed}
        save={(folder) => {
          return addFolder
            .onSuccess(() => {
              actionModalAddFolder.close()
              navigate(location, {
                state: {
                  message: `Cartella ${folder.name} aggiunta con successo`,
                },
              })
            })
            .asPromise({ ...folder, parent_folder: +folderId })
        }}
      />
      <ModalFile
        isOpen={modalAddFile.isOpen}
        toggle={actionModalAddFile.toggle}
        onExited={actionModalAddFile.onClosed}
        save={(data) =>
          addFile
            .onSuccess((file) => {
              actionModalAddFile.close()
              navigate(location, {
                state: {
                  message: `File ${file.name} aggiunto con successo`,
                },
              })
            })
            .asPromise({
              folder: folderId,
              ...data,
            })
        }
      />
      <ModalFolder
        isOpen={modalUpdateFolder.isOpen}
        toggle={actionModalUpdateFolder.toggle}
        folder={modalUpdateFolder.value}
        // onExited={actionModalAddFolder.onClosed}
        save={(folder) => {
          return patchFolder
            .onSuccess(() => {
              actionModalUpdateFolder.close()
              navigate(location, {
                state: {
                  message: `Cartella ${folder.name} aggiornata con successo`,
                },
              })
            })
            .asPromise(folder.id, folder)
        }}
      />
      <ModalFile
        isOpen={modalUpdateFile.isOpen}
        toggle={actionModalUpdateFile.toggle}
        onExited={actionModalUpdateFile.onClosed}
        file={modalUpdateFile.value}
        save={(data) => {
          return patchSharedFile
            .onSuccess((file) => {
              actionModalUpdateFile.close()
              navigate(location, {
                state: {
                  message: `File ${file.name} aggiornato con successo`,
                },
              })
            })
            .asPromise(modalUpdateFile.value.id, data)
        }}
      />
      <ModalPolicies
        isOpen={modalSetPolicies.isOpen}
        toggle={actionsModalSetPolicies.toggle}
        policies={policies ? policies : []}
        currentPolicies={modalSetPolicies.value}
        onExited={actionsModalSetPolicies.onClosed}
        save={(data) => {
          return patchFolder
            .onSuccess(actionsModalSetPolicies.close)
            .asPromise(modalSetPolicies.value.id, { policies: data.policies })
        }}
      />
      <ModalUsers
        isOpen={modalSetUsers.isOpen}
        toggle={actionsModalSetUsers.toggle}
        users={users ? users.filter((u) => !u.administrator) : []}
        currentUsers={modalSetUsers.value}
        onExited={actionsModalSetUsers.onClosed}
        save={(data) => {
          return patchFolder
            .onSuccess(actionsModalSetUsers.close)
            .asPromise(modalSetUsers.value.id, { users: data.users })
        }}
      />
      <ModalDelete
        isOpen={modalDelete.isOpen}
        toggle={actionsDelete.toggle}
        onExited={actionsDelete.onClosed}
        body={
          modalDelete.value
            ? `Sei sicuro di voler eliminare ${modalDelete.value.name}?`
            : ''
        }
        onDelete={() => {
          if (modalDelete.value.type === 'folder') {
            return deleteFolder
              .onSuccess(actionsDelete.close)
              .asPromise(modalDelete.value.id)
          } else if (modalDelete.value.type === 'file') {
            return deleteSharedFile
              .onSuccess(actionsDelete.close)
              .asPromise(modalDelete.value.id)
          }
        }}
      />
    </div>
  )
}
